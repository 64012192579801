import React from "react";
import { ArrowRight } from "@phosphor-icons/react";
import "./ProjectLayout.css";
import "../styles/Buttons.css";

function ProjectLayout({ title, liveLink, children }) {

  const handleClick = () => {
    window.open(liveLink, '_blank');
  };
  return (
    <div className="project-header-text">
      <h1 className="project-h1">{title}</h1>
      <div>{children}</div>
      <div className="project-description">
        {liveLink && (
          <p className="description-item">
            <button onClick={handleClick} className="button-light">
              Open Live Project
              <ArrowRight size={22} weight={"light"} />
            </button>
          </p>
        )}
      </div>
    </div>
    
  );
}

export default ProjectLayout;
