import { useState } from "react";
import ProjectFilter from "./ProjectFilter";
import ProjectCard from "./ProjectCard";
import styles from "./PortfolioSection.module.css";
import silverdesignThumbnail from '../assets/silverdesign-thumbnail2.png';
import zocdocThumbnail from '../assets/zocdoc-thumbnail.png';
import peoriaplayhouseThumbnail from '../assets/peoriaplayhouse-thumbnail.png';
import tatemodernThumnail from '../assets/tatemodern-thumbnail2.png';
import tateAnimation from "../assets/tate-lottie.json";
import zocdocAnimation from "../assets/zocdoc-lottie.json";
import silverAnimation from "../assets/silver-lottie.json";
import pekinAnimation from "../assets/piaudit-lottie.json";
import playhouseAnimation from "../assets/playhouse-lottie.json";
import pekinUXAnimation from "../assets/pekin-ux-lottie.json";

function PortfolioSection() {
  const [selectedFilter, setSelectedFilter] = useState("All Projects");

  const projects = [
    {
      slug: "insurance-research-strategy",
      title: "Leading the user research strategy to guide an insurance company's digital transformation",
      type: ["UX Research", "Usability Study", "Strategy"],
      color: "#FFD700",
      animation: pekinUXAnimation,
    },
    {
      slug: "silver-design-framework",
      image: silverdesignThumbnail,
      title: "Developing a design framework to better support senior users",
      type: ["UX Research", "Usability Study", "Accessibility"],
      color: "#FFD700",
      animation: silverAnimation,
    },
    {
      slug: "childrens-museum-information-architecture",
      image: peoriaplayhouseThumbnail,
      title: "Redesigning the Peoria PlayHouse mobile site for improved information architecture",
      type: ["UX Research", "UX Design"],
      color: "#FFD700",
      animation: playhouseAnimation,
    },
    {
      slug: "zocdoc-accessibility-research",
      image: zocdocThumbnail,
      title: "Improving access to healthcare providers for people with blindness and low vision",
      type: ["UX Research", "Accessibility Study", "Usability Study"],
      color: "#FFD700",
      animation: zocdocAnimation,
    },
    {
      slug: "navigate-the-tate",
      image: tatemodernThumnail,
      title: "Navigate the Tate: Building engagement with an integrated art museum experience",
      type: ["Usability Study", "UX Design", "UX Research"],
      color: "#FFD700",
      animation: tateAnimation,
    },
    // {
    //   slug: "insurance-accessibility",
    //   image: piaccessibilityThumbnail,
    //   title: "Laying the groundwork for more accessible and inclusive insurance web experiences",
    //   type: ["Usability Study", "Accessibility"],
    //   color: "#FFD700",
    //   animation: pekinAnimation,
    // },
  ];

  const filteredProjects = selectedFilter === "All Projects"
    ? projects
    : projects.filter(project => project.type.includes(selectedFilter));

  return (
    <div className={styles.portfolioSection}>
      <ProjectFilter 
        currentFilter={selectedFilter} 
        onFilterChange={setSelectedFilter}
      />
      <div className={styles.portfolioCards}>
        {filteredProjects.map((project) => (
          <ProjectCard
            key={project.slug}
            slug={project.slug}
            image={project.image}
            title={project.title}
            type={project.type}
            color={project.color}
            animation={project.animation}
          />
        ))}
      </div>
    </div>
  );
}

export default PortfolioSection;

