import React from "react";
import "./MySpaceProfile.css";
import portrait from "../assets/portrait-large2.png";
import {
  Envelope,
  LinkedinLogo,
  MediumLogo,
  Confetti,
  DotOutline,
} from "@phosphor-icons/react";
import SpotifyEmbed from "../components/SpotifyEmbed";
import favoriteThings from "../assets/favoritethings.png";


const MySpaceProfile = () => {
  return (
    <div className="main-content-home">
      <div className="myspace-container">
        {/* left section */}
        <div className="myspace-left-side">
          <h2 className="username">Johanna's Space</h2>
          <section className="myspace-header">
            <div className="profile-pic-container">
              <img src={portrait} alt="Profile" className="profile-pic" />
            </div>
            <div className="profile-info">
              <p>"Never half-ass two things. Whole-ass one thing." - Ron Swanson</p>
              <p className="bio">
                <span className="availability">
                  <DotOutline className="blinking" size={30} weight="bold" />{" "}
                  Available for work
                </span>
                <br />
                <div className="profile-info-text">
                  Central ILLINOIS,
                  <br />
                  United States
                </div>
                <div className="profile-info-text">
                  Last Login: <br />
                  09/13/2024
                </div>
              </p>
            </div>
          </section>
          <p>
            <strong>Mood: </strong> Creative 🤔
          </p>
          <section className="contact-section">
            <h2 className="contact-header">Contact Johanna</h2>
            <div className="contact-info">
              <ul>
                <li>
                  <Envelope size={15} weight="duotone" color="#3e6695" />
                  <a href="mailto:contact@johannasneed.com">Email Me</a>
                </li>
                <li>
                  <LinkedinLogo size={15} weight="duotone" color="#3e6695" />
                  <a href="www.linkedin.com/in/johannasneed">
                    LinkedIn Profile
                  </a>
                </li>
                <li>
                  <MediumLogo size={15} weight="duotone" color="#3e6695" />
                  <a href="https://medium.com/@johannasneed">Medium Articles</a>
                </li>
                <li>
                  <Confetti size={15} weight="duotone" color="#3e6695" />
                  <a href="https://medium.com/@johannasneed"><span className="secret-label"></span></a>
                </li>
              </ul>
            </div>
          </section>
          <section className="url-section">
            <p>
              <strong>Portfolio URL:</strong>
              <br />
              https://johannasneed.com
            </p>
          </section>
          <section className="interest-section">
            <h2 className="interest-header">Johanna's Interests</h2>
            <div className="interest-details">
              <div className="interest-left">
                <h3>General</h3>
              </div>
              <div className="interest-right">
                I'm a serial hobbiest, artist, crafter, birder, and gardener. I
                enjoy problem-solving and continuous learning.
              </div>
            </div>
            <div className="interest-details">
              <div className="interest-left">
                <h3>Music</h3>
      
              </div>
              <div className="interest-right">
              Music is one of my favorite things in the world, and I love all kinds of it. I'm especially a fan of heavy and noisy stuff. I am also a bit of a pop fan. Check out what's on repeat on my playlist.
      
              </div>
            </div>
            <div className="interest-details">
              <div className="interest-left">
                <h3>Television</h3>
              </div>
              <div className="interest-right">
                Currently watching The Devil's Plan on Netflix
              </div>
            </div>
            <div className="interest-details">
              <div className="interest-left">
                <h3>Movies</h3>
              </div>
              <div className="interest-right">
                I don't watch a ton of movies, but I can always rewatch Whiplash.
              </div>
            </div>
            <div className="interest-details">
              <div className="interest-left">
                <h3>Books</h3>
              </div>
              <div className="interest-right">
                Currently Reading:Local Woman Missing by Mary Kubica<br />
                Recommended Reading: <br />
                - Stuck? Diagrams Help by Abby Covert <br />
                - Customer Know You Suck by Debbie Levitt<br />
                - Quantifying the User Experience by Jeff Sauro and James R. Lewis <br />
                - Closing the Loop - Systems Thinking for Designers by Sheryl Cababa
              </div>
            </div>
      
          </section>
        </div>
        <div className="myspace-right-side">
          <section className="status-message-large">
            Johanna is wondering why she chose the ugliest social media site design to remake...
          </section>
          {/* <section className="blog-section">
            <h2>Johanna's Latest Blog Entry</h2>
            <ul>
              <li>
                Blog entry 1... <a href="">(view more)</a>
              </li>
              <li>
                Blog entry 1... <a href="">(view more)</a>
              </li>
              <li>
                Blog entry 1... <a href="">(view more)</a>
              </li>
            </ul>
          </section> */}
          <section className="blurbs-section">
            <div className="right-section-header">
              <h2>Favorite things</h2>
            </div>
            <div className="about-photos">
              <br />
            I enjoy marveling at the beautiful things in the world. 
                        <img src={favoriteThings} />
            </div>
            <SpotifyEmbed />
      
          </section>
        </div>
      </div>
    </div>
  );
};

export default MySpaceProfile;
