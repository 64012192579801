import React from 'react';

function SpotifyEmbed() {
  return (
    <div>
      <iframe
        style={{ borderRadius: '5px' }}
        src="https://open.spotify.com/embed/playlist/75sWjywGvvgL1TTtMu2DQe?utm_source=generator&theme=0"
        width="100%"
        height="352"
        frameBorder="0"
        allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
        loading="lazy"
        allowFullScreen
      ></iframe>
    </div>
  );
}

export default SpotifyEmbed;
