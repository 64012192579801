// Article images from public folder
const images = {
  progressive: "/static/articles/ageism/progressive.png",
  webMD: "/static/articles/ageism/webMD.png",
  iphone: "/static/articles/ageism/iphonetouch.png",
  medicare: "/static/articles/ageism/medicare.png",
  nia: "/static/articles/ageism/nia.png",
  dior: "/static/articles/ageism/dior.png",
  transformers: "/static/articles/transformers-example.png"
};

const articles = [
  {
    id: 1,
    slug: "hi-fi-proto-agile",
    title: "Balancing high fidelity prototyping and agile in digital design",
    thumbnail: "/static/articles/lo-fi-prototype.jpg", // Update path to match public folder structure
    publishDate: "November 26, 2023",
    type: "UX Design",
    blurb:
      "Communication and consideration of how designs are shared are critical to an effective design process.",
    contentPath: "/articles/hi-fi-proto-agile.html"
  },
  {
    id: 2,
    slug: "bci-and-games",
    title: "Exploring game interaction using brain-computer interfaces",
    thumbnail: "/static/articles/gaming-bci.jpg", // Update path to match public folder structure
    publishDate: "December 26, 2023",
    type: "HCI Research",
    blurb: `How might low-risk, non-invasive brain-computer interface devices provide a novel and more inclusive gaming experience?`,
    contentPath: "/articles/bci-and-games.html"
  },
  {
    id: 5,
    slug: "is-modern-design-ageist",
    title: "Research backed approaches to design user interfaces for seniors",
    thumbnail: "/static/articles/ageism.jpg", // Use public path
    publishDate: "September 23, 2024",
    type: "HCI Research",
    blurb:
      "Modern web design moves fast, and as interfaces become more minimal, one group is often left out: older adults.",
    contentPath: "/articles/is-modern-design-ageist.html"
  },
  {
    id: 6,
    slug: "accessibility-in-sdlc",
    title:
      "How to incorporate accessibility into every step of the software development lifecycle",
    thumbnail: "/static/articles/accessibility-article.jpg", // Use public path
    publishDate: "November 21, 2024",
    type: "Digital Accessibility",
    blurb:"By embedding accessibility into every phase of the software development lifecycle (SDLC), we create products that work for everyone.",
    contentPath: "/articles/accessibility-in-sdlc.html"
  },
  {
    id: 7,
    slug: "insurance-accessibility",
    title: "Case Study: Conducting a digital accessibility audit for an insurance website",
    thumbnail: "/static/articles/piaccessibility-thumbnail.png", 
    publishDate: "August 4, 2024",
    type: "Digital Accessibility",
    blurb:"This case study outlines an accessibility audit I conducted in May 2024, focusing on enhancing site usability for all users.",
    contentPath: "/articles/insurance-accessibility.html"
  }
];

export { articles };
