import React from 'react';
import PortfolioSection from "../components/PortfolioSection";
import "../styles/PageLayout.css";

const Portfolio = () => {
  return (
    <div className="page-content">
      <div className="page-inner" style={{ maxWidth: '1400px', margin: '0 auto' }}>
        <div className="page-header">
          <h1 className="page-title">Portfolio Case Studies</h1>
          <p className="page-description">
            A collection of my work in UX research and design.
          </p>
        </div>
        <div style={{ width: '100%' }}>
          <PortfolioSection />
        </div>
      </div>
    </div>
  );
};

export default Portfolio;