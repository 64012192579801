import React from "react";
import ProjectLayout from "../components/ProjectLayout";
import "./Project.css";
import styles from "./Project6.module.css";
import {
  Graph,
  UsersThree,
  UserSound,
  ChalkboardTeacher,
  Handshake,
  Target,
  Lightbulb,
  Question,
  Strategy,
  Kanban,
} from "@phosphor-icons/react";
import internalLottie from "../assets/project6/internal-insights.json";
import Lottie from "lottie-react";
import serviceBlueprint from "../assets/project6/service-blueprint1.png";
import agent1 from "../assets/project6/agent1.png";
import agent2 from "../assets/project6/agent2.png";
import agent3 from "../assets/project6/agent3.png";
import agent4 from "../assets/project6/agent4.png";
import competitionImage from "../assets/project6/competition.png";
import heatmap from "../assets/project6/heatmap.png";
import contextMap from "../assets/project6/context-map.png";
import stategyMap from "../assets/project6/strategy-map.png";
import piApps from "../assets/project6/pi-apps.png";
import evaluateUX from "../assets/project6/evaluate-ux.png";

function Project6() {
  return (
    <>
      <div className="dark">
        <ProjectLayout title="Leading a user research strategy to guide an insurance company's digital transformation">
          <p>
            Pekin Insurance embarked on a digital transformation to improve
            customer and agent experiences. My role as Digital Experience
            Manager involved establishing a robust user research strategy to
            inform decisions, enhance products, and ensure alignment with user
            needs. The focus was on creating a foundation for research-driven
            design, improving accessibility, and fostering innovation across
            digital products like customer portals and agent tools. The strategy
            I implemented laid the foundation for a digital transformation that
            improved usability, accessibility, and operational efficiency, while
            fostering a collaborative culture where user insights drove
            meaningful change.
          </p>
          <p className="disclaimer-text">
            Some information in this case study may be changed to protect
            confidential or private information. All information and views
            presented in this case study are my own and do not reflect the views
            of Pekin Insurance.
          </p>
        </ProjectLayout>
      </div>
      <div className="main-content">
        <div className="case-study-details">
          <h2>The Challenge</h2>
          <p>
            Pekin Insurance was at a crossroads, facing challenges with outdated
            digital platforms that failed to meet the evolving expectations of
            agents and insured customers. These gaps weren’t just technical.
            They reflected a deeper disconnect between user needs and business
            priorities. As the Digital Experience Technology Manager, I led the
            charge to redefine the company’s approach through a digital
            experience strategy, which incorporated establishing a UX research
            practice. This effort wasn’t just about modernizing, it was about
            transforming the company's mindset to embrace user-centered
            practices as a catalyst for innovation.
          </p>
          <p className="emphasized-text">
            Objective: Establish a user research strategy to identify user pain
            points, improve product usability, and support a user-centered
            digital transformation.
          </p>
          <hr />
          <h2>My Role</h2>
          <p>
            In this pivotal role, I wore many hats and adapted to the dynamic
            needs of the organization. My journey at Pekin Insurance can be seen
            in two distinct chapters:
          </p>
          <div className={styles.roleSection}>
            <div className={styles.roleContainer}>
              <h3>The First 10 Months - Building the Foundation</h3>
              <ul className={styles.roleList}>
                <li>
                  <div>
                    <Graph size={32} color="#6ab04c" weight="fill" />
                  </div>
                  <div>
                    <strong>
                      Created the insured experience strategy and product vision
                    </strong>
                    , aligning user needs with business goals.
                  </div>
                </li>
                <li>
                  <div>
                    <ChalkboardTeacher
                      size={32}
                      color="#6ab04c"
                      weight="fill"
                    />
                  </div>
                  <div>
                    <strong>Mentored and coached team members</strong> ensuring
                    they could take ownership of projects.
                  </div>
                </li>
                <li>
                  <div>
                    <UsersThree size={32} color="#6ab04c" weight="fill" />
                  </div>
                  <div>
                    <strong>Supported my team in every way possible</strong>,
                    whether it was rolling up my sleeves for usability testing
                    or bringing in new tools and resources.
                  </div>
                </li>
              </ul>
            </div>

            <div>
              <h3>The Next 12 Months - Expanding the Vision</h3>
              <ul className={styles.roleList}>
                <li>
                  <div>
                    <UserSound size={32} color="#6ab04c" weight="fill" />
                  </div>
                  <div>
                    <strong>
                      Assumed responsibility for the Agent Experience and the
                      public website
                    </strong>{" "}
                    in addition to insured customers, broadening the scope of
                    our impact.
                  </div>
                </li>
                <li>
                  <div>
                    <Handshake size={32} color="#6ab04c" weight="fill" />
                  </div>
                  <div>
                    Championed a proactive approach to addressing agent pain
                    points, <strong>
                      ensuring tools were improved for efficiency and
                      effectiveness
                    </strong>.
                  </div>
                </li>
                <li>
                  <div>
                    <Target size={32} color="#6ab04c" weight="fill" />
                  </div>
                  <div>
                    <strong>
                      Led team through establishing agent experience goals{" "}
                    </strong>{" "}
                    through agent interviews, workshops, and analytics.
                  </div>
                </li>
              </ul>
            </div>
          </div>
          <hr />
          <h2>My Approach</h2>
          <p>
            The transformation was guided by a structured, iterative approach,
            ensuring that every step was grounded in research and aligned with
            business outcomes.
          </p>
          <div className={styles.approachContainer}>
            <div>
              <h3>
                <Lightbulb size={20} color="#686de0" weight="fill" /> Discover
              </h3>
              <p>
                <strong>Conducted in-depth mixed-methods research</strong> using
                interviews, workshops, competitive analysis, data analysis, and
                workflow mapping to uncover the pain points impacting agents and
                customers. Insights ranged from usability challenges to gaps in
                technical integration.
              </p>
            </div>
            <div>
              <h3>
                <Question size={20} color="#686de0" weight="fill" /> Define
              </h3>
              <p>
                <strong>
                  Synthesized research findings into actionable opportunities
                </strong>{" "}
                and prioritized them based on organizational goals. I created
                service blueprints and journey maps that highlighted friction
                points and opportunities to align systems, processes, and tools
                with user needs.
              </p>
            </div>
            <div>
              <h3>
                <Strategy size={20} color="#686de0" weight="fill" /> Strategize
              </h3>
              <p>
                <strong>
                  Presented clear, data-driven plans to leadership and
                  stakeholders
                </strong>
                , connecting research insights to measurable outcomes. By tying
                findings to OKRs like increased task success rates and reduced
                support costs, I secured leadership buy-in for transformative
                changes.
              </p>
            </div>
            <div>
              <h3>
                <Kanban size={20} color="#686de0" weight="fill" /> Sustain
              </h3>
              <p>
                <strong>Established a research framework</strong> that empowered
                teams to continue iterating on user insights. I focused on
                mentoring team members and embedding a culture of research,
                ensuring that the transformation would be sustainable beyond my
                tenure.
              </p>
            </div>
          </div>
          <hr />
          <h2>Goals</h2>
          <ul className="ul-arrows">
            <li>
              Identify key pain points in the customer and agent experiences.
            </li>
            <li>
              Develop a research-driven roadmap for portal redesigns and new
              features.
            </li>
            <li>
              Ensure accessibility compliance and improved usability for diverse
              user groups.
            </li>
            <li>
              Create a DX strategy to foster a user-centered culture within the
              organization using mixed-methods research.
            </li>
          </ul>
          <hr />
          <h2>
            {" "}
            <Lightbulb size={30} color="#686de0" weight="fill" /> Discover:
            Research and Methods
          </h2>
          <h3>Internal Interviews</h3>
          <p>
            To understand the full landscape of user interactions, I conducted
            interviews across underwriting, claims, helpdesk, and other business
            areas. These conversations provided a nuanced understanding of how
            agents and insured customers interact with the company and
            highlighted internal perceptions about customer experience.
          </p>
          <h4>Key Insights</h4>
          <div className={styles.internalInterviews}>
            <ul>
              <li>
                Agents were seen as "the real customers" by internal teams due
                to their role in generating revenue and being supported by IT
                and business initiatives.
              </li>
              <li>
                Insured customers, skewing older (50+) and residing in rural
                areas, often faced challenges with unsupported legacy apps and
                limited digital tools.
              </li>
              <li>
                There was hesitancy to engage directly with insured customers,
                with concerns that it might create tension with agents.
              </li>
            </ul>
          </div>
          <Lottie
            animationData={internalLottie}
            loop={true}
            autoplay={true}
            styles={{
              width: "100%",
              margin: "2rem auto",
            }}
          />
          <p className={styles.impact}>
            <strong>Impact:</strong> These insights highlighted a significant
            gap in how the organization prioritized user groups, shaping the
            need for equitable solutions that addressed both agent and insured
            pain points.
          </p>
          <h3>Service Blueprinting</h3>
          <p>
            Collaborating with the claims team, I developed a service blueprint
            to map the end-to-end claims journey. This exercise identified
            friction points, such as:
          </p>
          <ul className="ul-arrows">
            <li>
              Delayed communication between agents and claims representatives,
              leading to longer resolution times.
            </li>
            <li>
              Inconsistent reporting tools that added confusion to the process.
            </li>
            <li>
              Reliance on manual workflows, causing inefficiencies at multiple
              touchpoints.
            </li>
          </ul>
          <img
            src={serviceBlueprint}
            alt="Service Blueprint example"
            className="mockup-image-xl"
          />
          <p className={styles.impact}>
            <strong>Outcome:</strong> The blueprint provided a clear
            visualization of the claims process, pinpointing opportunities for
            automation, streamlined communication, and improved user flows.
          </p>
          
          <h3>User Interviews</h3>
          <p>
            Building trust with agents was critical for uncovering the real
            challenges they faced. Through interviews conducted by office visits
            and phone calls, I learned how their frustrations with existing
            tools impacted their ability to serve insured customers.
          </p>
          <h4>Direct Feedback</h4>
          <div className={styles.chatBubbles}>
            <div className={styles.chats}>
              <img src={agent1} alt="" className={styles.agentImg} />
              <div className={`${styles.fromThem} ${styles.loud}`}>
                <p>We have our own agency apps.</p>
              </div>
            </div>
            <div className={styles.chats}>
              <img src={agent2} alt="" className={styles.agentImg} />
              <div className={`${styles.fromThem} ${styles.loud}`}>
                <p>I would be happy if I no longer have to fill out claims!</p>
              </div>
            </div>
            <div className={styles.chats}>
              <img src={agent3} alt="" className={styles.agentImg} />
              <div className={`${styles.fromThem} ${styles.loud}`}>
                <p>
                  I can barely submit a quote some days. I need solutions that
                  won't cause me and my customers more headaches.
                </p>
              </div>
            </div>
            <div className={styles.chats}>
              <img src={agent4} alt="" className={styles.agentImg} />
              <div className={`${styles.fromThem} ${styles.loud}`}>
                <p>
                  Most companies are already doing this. Do whatever they do.
                </p>
              </div>
            </div>
          </div>
            <ul className="ul-arrows">
              <li>
                Agents often relied on their own tools, bypassing our systems.{" "}
              </li>
              <li>
                Agents expressed a strong desire for more self-service
                capabilities for customers, especially for processes like
                Claims, where they are the middle person between the customer
                and the company.
              </li>
              <li>
                Agents voiced skepticism in the company's ability to build
                reliable digital solutions.
              </li>
              <li>
                Many agents were eager for Pekin to align with modern industry
                standards.
              </li>
            </ul>
            <p className={styles.impact}>
              <strong>Impact:</strong> These candid insights reinforced the
              importance of addressing usability and reliability issues while
              demonstrating to agents that their feedback was valued.
            </p>
          <h3>Competitive Analysis</h3>
          <p>
            Competitive Analysis To benchmark against industry standards, I
            conducted a thorough competitive analysis of digital solutions from
            major players like State Farm, Progressive, Liberty Mutual, and
            American Modern. This review helped identify best practices and gaps
            in Pekin Insurance’s offerings.
          </p>
          <img
            src={competitionImage}
            alt="Screen grabs of State Farm, Progressive, Liberty Mutual, and American Modern mobile app home screens"
            className="mockup-image-xl"
          />
          <h4>Key Findings</h4>
          <ul className="ul-arrows">
            <li>
              Modern insurance apps provided{" "}
              <strong>
                personalized dashboards, self-service capabilities
              </strong>
              , and integrated tools for policy management and claims tracking.
            </li>
            <li>
              Competitors heavily prioritized
              <strong> mobile accessibility</strong>, reflecting the growing
              importance of on-the-go interactions.
            </li>
          </ul>
          <p className={styles.impact}>
            <strong>Impact:</strong> These insights established a baseline for
            user expectations, shaping the vision for an improved digital
            experience that prioritized seamless, self-service functionality.
          </p>
          <h3>Finding Data - Ticket Analysis </h3>
          <p>
            It was clear that data was hard to come by as analytics tools were
            used minimally. By analyzing two years’ worth of support tickets, I
            identified recurring pain points that directly impacted user
            satisfaction and operational efficiency.
          </p>
          <h4>Heatmap Findings</h4>
          <ul className="ul-arrows">
            <li>
              29.66% of tickets were related to login failures, reflecting
              technical debt and outdated authentication processes.{" "}
            </li>
            <li>
              15.91% were misdirected, indicating unclear guidance and possible
              internal process improvement opportunities.
            </li>
            <li>
              The data revealed a persistent lack of intuitive design, leading
              to reliance on human support for tasks that should be
              self-service.
            </li>
          </ul>
          <img
            src={heatmap}
            alt="Heatmap of support ticket data"
            className="mockup-image-500"
          />
          <p className={styles.impact}>
            <strong>Impact:</strong> These findings informed immediate
            priorities for the digital transformation roadmap, including
            enhanced login experiences and clearer navigation paths.
          </p>
          <h3>Context Mapping</h3>

          <p>
            Using context mapping, I visualized relationships and dependencies
            between insured customers, agents, and internal business functions
            like claims, underwriting, and IT. This exercise highlighted
            systemic issues, including:
          </p>

          <img
            src={contextMap}
            alt="Context Map showing the relationship between customers, claims, sales, underwriting, IT and hel desk"
            className="mockup-image-xl"
          />

          <p className={styles.impact}>
            <strong>Outcome:</strong> The map clarified areas where improved
            communication and automation could deliver the most value, enabling
            cross-functional alignment.
          </p>

          <hr />
          <h2>
            <Strategy size={30} color="#686de0" weight="fill" /> Strategize:
            Delivery Mapping
          </h2>
          <p>
            I designed a strategy-to-delivery map to align company goals,
            department objectives, and measurable outcomes. The map connected
            opportunities and solutions to strategic initiatives, emphasizing
            self-service improvements and operational efficiencies.
          </p>
          <img
            src={stategyMap}
            alt="Delivery Map connecting company goals, objectives, and outcomes"
            className="mockup-image-xl"
          />
          <h4>Key Elements</h4>
          <ul className="ul-arrows">
            <li>
              Defined <strong>top-level strategies</strong> and linked them to
              actionable objectives.
            </li>
            <li>
              Identified key opportunities, like paperless billing integration
              and agent onboarding optimization.
            </li>
            <li>
              Outlined measurable outcomes, such as cost savings and improved
              task success rates.
            </li>
          </ul>
          <p className={styles.impact}>
            <strong>Impact:</strong> This framework enabled leadership to
            visualize how strategic goals were directly supported by actionable
            initiatives, fostering alignment across teams.
          </p>

          <hr />

          <h2>
            <Question size={30} color="#686de0" weight="fill" /> Define:
            Tactical Roadmap
          </h2>
          <p>
            Based on the findings from research and discovery, I developed a{" "}
            <strong>tactical roadmap</strong> to prioritize features for the{" "}
            <strong>MVP</strong> and subsequent releases (R1, R2).
          </p>

          <h4>MVP & Iterations</h4>
          <ul className="ul-arrows">
            <li>
              <strong>MVP:</strong> Integrated modern authentication, created
              enterprise customer records, and introduced a modern payment
              solution.
            </li>
            <li>
              <strong>R1:</strong> Implemented paperless billing, claims status
              tracking, and digital wallet ID cards.
            </li>
            <li>
              <strong>R2:</strong> Introduced forms for commercial and life
              insurance as well as first notice of loss features.
            </li>
          </ul>
          <p className={styles.impact}>
            <strong>Impact:</strong> This phased approach maximized immediate
            value while ensuring long-term sustainability and growth.
          </p>

          <hr />

          <h2>
            <Kanban size={30} color="#686de0" weight="fill" /> Sustain:
            Evaluating the UX
          </h2>
          <p>
            To ensure continued improvement, I established a{" "}
            <strong>UX research framework</strong>
            that focused on usability evaluations, accessibility testing, and
            standardized processes for collecting and analyzing data.
          </p>
          <img
            src={evaluateUX}
            alt="Pyramid showing the steps to evaluate UX"
            className="mockup-image-xl"
          />

          <h4>Core Framework Elements</h4>
          <ul className="ul-arrows">
            <li>Set a clear vision goals.</li>
            <li>
              Ensure stakeholders are updated frequently and involved in
              decision making.
            </li>
            <li>Establish and guide research methods and processes.</li>
            <li>
              Standardized test scripts and logs to ensure consistent data
              collection through resources and templates.
            </li>
            <li>
              Regular coaching, mentoring, and feedback to upskill team members
              in research methods.
            </li>
          </ul>
          <p className={styles.impact}>
            <strong>Impact:</strong> This framework ensured that data-driven
            insights continued to improve usability and accessibility across
            digital products.
          </p>
          <hr />
          <h2>Outcomes</h2>
          <h3>Improved Customer Experience</h3>
          <p>
            The digital transformation at Pekin Insurance began delivering
            tangible results, marking significant progress in improving user
            experiences for both agents and insured customers.
          </p>
          <ol>
          <li>
              <strong>
                Fixed and enabled over 90 new agency user experience features in 6 months.
              </strong>{" "}
              This effort showed a commitment to addressing user pain points and drastically improved user experience feedback from agents. This enabled the organization to decide to open up a previously closed line of business, generating more business.
            </li>
            <li>
              <strong>
                On-track to launch the new customer portal by year-end 2024.
              </strong>{" "}
              This initiative not only enhances the overall user experience but
              also positions the company to save $5M annually by improving
              operational efficiency and reducing support costs.
            </li>
            <li>
              <strong>Claims team innovations.</strong> By implementing new
              processes and technologies, the claims team was better equipped to
              support customers, reducing friction in critical workflows in
              their solutions.
            </li>
            <li>
              <strong>Leveraging insights for immediate impact.</strong> Lessons
              learned from agent interviews were quickly applied to establish
              goals that improved the agent experience. This effort contributed
              to notable reduction of defects and improvements in agent
              satisfaction scores within the first quarter of implementation.
            </li>
            <li>
              <strong>Alignment with organizational strategy.</strong> Every UX
              effort directly supported business objectives, such as cost
              savings, operational efficiencies, and enhanced customer
              satisfaction.
            </li>
            <li>
              <strong>Building UX research foundations.</strong> Establishing a
              sustainable UX research framework laid the groundwork for
              continuous improvement and a user-centered culture.
            </li>
          </ol>
          <p>
            To showcase these improvements, the portal redesign includes
            intuitive self-service tools, clear navigation, and modern features
            that align with customer expectations. The new design prioritizes
            accessibility, usability, and efficiency, providing a seamless
            experience across devices.{" "}
          </p>

          <img
            src={piApps}
            alt="Pekin Insurance Apps, including desktop and mobile"
            className="mockup-image-xl"
          />
          <p className={styles.disclaimer}>
            <strong>Note:</strong> These designs were created by the talented UX
            / visual design members of my team.
          </p>
        </div>
      </div>
    </>
  );
}

export default Project6;
