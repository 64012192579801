import React from "react";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import { seoData } from './config/seo';
import SEO from './components/SEO';
import Sidebar from "./components/Sidebar";
import Home from "./pages/Home";
import About from "./pages/About";
import Projects from "./pages/Projects";
import "./App.css";
import CaseStudy from "./pages/CaseStudy";
import ScrollToTop from "./ScrollToTop";
import WritingsPage from "./pages/Writings";
import Footer from "./components/Footer";
import ArticleDetail from "./components/ArticleDetail";
import MySpaceProfile from "./pages/MySpaceProfile";
import NotFound from "./pages/404";
import Now from "./pages/Now";
import Portfolio from "./pages/Portfolio";
import { HelmetProvider } from 'react-helmet-async';
import { MotionConfig, LazyMotion, domMax } from "framer-motion";

// Create Layout component to wrap routes
const Layout = ({ children }) => (
  <div className="app-container">
    <Sidebar />
    <main className="content-container" role="main" tabIndex="-1">
      {children}
    </main>
    <ScrollToTop />
  </div>
);

// Create router configuration
const router = createBrowserRouter([
  {
    path: "/",
    element: <Layout><Home /></Layout>,
  },
  {
    path: "/about",
    element: <Layout><About /><Footer /></Layout>,
  },
  {
    path: "/projects",
    element: (
      <Layout>
        <Projects />
        <Footer />
      </Layout>
    ),
  },
  {
    path: "/writing",
    element: (
      <Layout>
        <SEO {...seoData.writing} />
        <WritingsPage />
        <Footer />
      </Layout>
    ),
  },
  {
    path: "/now",
    element: (
      <Layout>
        <Now />
        <Footer />
      </Layout>
    ),
  },
  {
    path: "/case-study/:slug",
    element: (
      <Layout>
        <CaseStudy />
        <Footer />
      </Layout>
    ),
  },
  {
    path: "/articles/:slug",
    element: (
      <Layout>
        <React.Suspense fallback={<div>Loading...</div>}>
          <ArticleDetail seoData={seoData.articles} />
          <Footer />
        </React.Suspense>
      </Layout>
    ),
  },
  {
    path: "/myspace",
    element: (
      <Layout>
        <MySpaceProfile />
        <Footer />
      </Layout>
    ),
  },
  {
    path: "/portfolio",
    element: (
      <Layout>
        <Portfolio />
        <Footer />
      </Layout>
    ),
  },
  {
    path: "*",
    element: (
      <Layout>
        <NotFound />
        <Footer />
      </Layout>
    ),
  },
], {
  future: {
    v7_startTransition: true,
    v7_relativeSplatPath: true
  }
});

function App() {
  return (
    <HelmetProvider>
      <LazyMotion features={domMax}>
        <MotionConfig reducedMotion="user">
          <RouterProvider router={router} />
        </MotionConfig>
      </LazyMotion>
    </HelmetProvider>
  );
}

export default App;
