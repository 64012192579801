import { Helmet } from 'react-helmet-async';

const SEO = ({ title, description, image, type = 'website', imageWidth = 1200, imageHeight = 630 }) => {
  // Handle SSR case where window might not be available
  const siteUrl = typeof window !== 'undefined' ? window.location.origin : 'https://johannasneed.com';
  const currentUrl = typeof window !== 'undefined' ? window.location.href : `${siteUrl}${window.location.pathname}`;
  
  // Ensure image URL is absolute
  const imageUrl = image ? (image.startsWith('http') ? image : `${siteUrl}${image}`) : `${siteUrl}/og-image.png`;

  return (
    <Helmet>
      <html lang="en" />
      <title>{title}</title>
      <link rel="canonical" href={currentUrl} />
      
      {/* Standard meta tags */}
      <meta name="robots" content="index, follow" />
      <meta name="description" content={description} />
      
      {/* Open Graph */}
      <meta property="og:url" content={currentUrl} />
      <meta property="og:type" content={type} />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:image" content={imageUrl} />
      <meta property="og:image:url" content={imageUrl} />
      <meta property="og:image:secure_url" content={imageUrl} />
      <meta property="og:image:width" content={imageWidth.toString()} />
      <meta property="og:image:height" content={imageHeight.toString()} />
      <meta property="og:image:type" content="image/jpeg" />
      <meta property="og:site_name" content="Johanna Sneed" />
      
      {/* Facebook */}
      <meta property="fb:app_id" content="your-fb-app-id" />
      
      {/* Twitter */}
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
      <meta name="twitter:image" content={imageUrl} />
    </Helmet>
  );
};

export default SEO;