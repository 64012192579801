import React from 'react';
import './Home.css';
import PortfolioSection from '../components/PortfolioSection.jsx';

const Projects = () => {
  return (
    <div className='main-content-home' role="main" aria-label="Projects Section">
        <PortfolioSection />
    </div>
  );
};

export default Projects;
