import { useRef, useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Lottie from 'lottie-react';
import styles from './ProjectCard.module.css';

function ProjectCard({ slug, image, title, type, animation }) {
  const [animationError, setAnimationError] = useState(false);
  const lottieRef = useRef(null);

  // Add debug logging
  useEffect(() => {
    if (animation) {
    }
  }, [animation, title]);

  const handleAnimationError = () => {
    setAnimationError(true);
  };

  const handleMouseEnter = () => {
    if (animation && lottieRef.current && !animationError) {
      lottieRef.current.play();
    }
  };

  const handleMouseLeave = () => {
    if (animation && lottieRef.current && !animationError) {
      lottieRef.current.stop();
    }
  };

  return (
    <Link 
      to={`/case-study/${slug}`} 
      className={styles.cardLink} 
      aria-label={`View case study: ${title}`}
    >
      <div 
        className={styles.card}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        <div className={styles.imageContainer}>
          {animation && !animationError ? (
            <Lottie 
              animationData={animation}
              lottieRef={lottieRef}
              onError={handleAnimationError}
              style={{ minWidth: '100%', minHeight: '100%', objectFit: 'cover' }}
              loop
              autoplay={false}
              renderer="svg"
            />
          ) : (
            <img 
              src={image} 
              alt={`Thumbnail for ${title}`} 
              className={styles.image} 
            />
          )}
        </div>
        <div className={styles.cardText}>
          <h2 className={styles.title} tabIndex="0">{title}</h2>
          <div className={styles.typePills}>
            {type.map((item, index) => (
              <span key={index} className={styles.typePill}>
                {item}
              </span>
            ))}
          </div>
        </div>
      </div>
    </Link>
  );
}

export default ProjectCard;
