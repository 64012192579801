import React from 'react';
import ArticlesList from '../components/ArticlesList';
import "../styles/PageLayout.css";

const WritingsPage = () => {
  return (
    <div className="page-content">
      <div className="page-inner" style={{ maxWidth: '1400px', margin: '0 auto' }}>
        <div className="page-header">
          <h1 className="page-title">Blog</h1>
          <p className="page-description">
            Thoughts and insights on technology, research, and design.
          </p>
        </div>
        <div style={{ width: '100%' }}>
          <ArticlesList />
        </div>
      </div>
    </div>
  );
};

export default WritingsPage;
