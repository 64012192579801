import React from 'react';
import './ProjectFilter.css';    

const ProjectFilter = ({ currentFilter, onFilterChange }) => {
  const filters = [
    "All Projects",
    "UX Research",
    "UX Design",
    "Usability Study"

  ];

  return (
    <div className="project-filter">
      {filters.map((filter) => (
        <button
          key={filter}
          className={`filter-button ${currentFilter === filter ? 'active' : ''}`}
          onClick={() => onFilterChange(filter)}
        >
          {filter}
        </button>
      ))}
    </div>
  );
};

export default ProjectFilter;