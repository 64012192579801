import { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { FaBars } from "react-icons/fa";
import "./Sidebar.css";
import { User, Folder, PencilLine, CalendarDot, List } from "@phosphor-icons/react";

const Sidebar = () => {
  const location = useLocation();
  const [isOpen, setIsOpen] = useState(false);

  const toggleSidebar = () => {
    setIsOpen(!isOpen);
  };

  const handleLinkClick = () => {
    setIsOpen(false);
  };

  return (
    <>
      <button
        className={`sidebar-toggle ${isOpen ? "open" : ""}`}
        onClick={toggleSidebar}
        aria-label="Toggle sidebar"
        aria-expanded={isOpen}
      >
        <div className="logo-container">
          <span className="logo-text">
            <span className="logo-letter">J</span>
            <span className="logo-separator">/</span>
            <span className="logo-letter">S</span>
          </span>
        </div>
        <FaBars size={24} color="var(--off-white)" />
      </button>
      <div className={`sidebar-container ${isOpen ? "open" : ""}`}>
        <div className="sidebar-header-container">
          <Link to="/" style={{ textDecoration: "none" }}>
            <div className="logo-container">
              <span className="logo-text">
                <span className="logo-letter">J</span>
                <span className="logo-separator">/</span>
                <span className="logo-letter">S</span>
              </span>
            </div>
          </Link>
        </div>
        <hr className="sidebar-hr" />
        <Link
          to="/"
          className={`sidebar-item ${
            location.pathname === "/" ? "active" : ""
          }`}
          aria-current={location.pathname === "/" ? "page" : undefined}
          onClick={handleLinkClick}
        >
          <User color={"var(--off-white)"} size={15} />
          <div className="sidebar-link">Home</div>
        </Link>
        <Link
          to="/portfolio"
          className={`sidebar-item ${
            location.pathname === "/portfolio" ? "active" : ""
          }`}
          aria-current={location.pathname === "/portfolio" ? "page" : undefined}
          onClick={handleLinkClick}
        >
          <Folder color={"var(--off-white)"} size={15} />
          <div className="sidebar-link">UX Case Studies</div>
        </Link>
        <Link
          to="/writing"
          className={`sidebar-item ${
            location.pathname === "/writing" ? "active" : ""
          }`}
          aria-current={location.pathname === "/writing" ? "page" : undefined}
          onClick={handleLinkClick} 
        >
          <PencilLine color={"var(--off-white)"} size={15} />
          <div className="sidebar-link">Blog</div>
        </Link>
        <Link
          to="/now"
          className={`sidebar-item ${
            location.pathname === "/now" ? "active" : ""
          }`}
          aria-current={location.pathname === "/now" ? "page" : undefined}
          onClick={handleLinkClick}
        >
          <CalendarDot color={"var(--off-white)"} size={15} />{" "}
          <div className="sidebar-link">Now</div>{" "}
        </Link>

        <Link
          to="/about"
          className={`sidebar-item ${
            location.pathname === "/about" ? "active" : ""
          }`}
          aria-current={location.pathname === "/about" ? "page" : undefined}
          onClick={handleLinkClick}
        >
          <User color={"var(--off-white)"} size={15} />{" "}
          <div className="sidebar-link">About Me</div>{" "}
        </Link>
      </div>{" "}
    </>
  );
};
export default Sidebar;
