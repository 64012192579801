import React from "react";
import ProjectLayout from "../components/ProjectLayout";
import "./Project.css";
import processImage from "../assets/project5/p5-process.png";
import interviewImage from "../assets/project5/interview-diagram.png";
import personaSpectrum from "../assets/project5/persona-spectrum.png";
import personaBenjamin from "../assets/project5/persona-benjamin.png";
import personaMaude from "../assets/project5/persona-maude.png";
import personaJourney from "../assets/project5/persona-journeys.png";
import featureMatrix from "../assets/project5/feature-matrix.png";
import userStories from "../assets/project5/user-stories.png";
import colorfulImage from "../assets/project5/colorful-image.png";
import conceptDiagram from "../assets/project5/concept-diagram.png";
import tateWireframes from "../assets/project5/tate-wireframes.png";
import TateImage from "../assets/tatemodern-thumbnail3.png";

function Project5() {
  return (
    <>

      <div className="dark">
        <ProjectLayout title="Navigate the Tate: Building engagement with an integrated art museum experience">
          <p>
            Navigate the Tate is a conceptual project for the Tate Modern
            gallery in London, England. ‍This integrated experience enables
            gallery visitors to make the most of their visit by efficiently
            navigating the gallery and creating long-lasting memories. Visitors
            will feel satisfied knowing that they’ve seen all the art they want
            to see and connected with the exhibits they love through physical
            and digital keepsakes. This project was completed for a
            User-Centered Design graduate class, completed over the course of 12
            weeks beginning September 2022.
          </p>
          <p className="disclaimer-text">
            Some information in this case study may be changed to protect
            confidential or private information. All information and views
            presented in this case study are my own and do not reflect the views
            of the Tate Modern.
          </p>
        </ProjectLayout>
      </div>
      <div className="main-content">
        <div className="case-study-details">
          <h2>The Challenge</h2>
          <p>
            Visitors to large art galleries like the Tate Modern often face
            challenges navigating exhibits, finding information, and enhancing
            their experience through digital tools. The Navigate the Tate app
            was designed to improve this experience by offering digital ticket
            access, gallery maps, and a feature to request printed postcards of
            artworks. However, the app’s usability had not been fully tested,
            and there was uncertainty about how intuitive and user-friendly it
            would be for a broad range of users, from tourists to art
            enthusiasts.
          </p>
          <p className="emphasized-text">
            How might an integrated solution improve the Tate Modern gallery
            visit experience?
          </p>
          <hr />
          <h2>My Role</h2>
          <p>
            I played a key role in planning and conducting user interviews and
            evaluations, persona creationg, system concept design and mapping,
            and created the prototype. This project was completed with a 4
            person project team.
          </p>
          <hr />
          <h2>Initial Research</h2>
          <p>
            The team used a user-centered design approach. I conducted 3 of 8
            user interviews and analyzed the results with my team to gather
            insights. Once clear opportunities for an integrated experience
            arose, we began the conceptual designs and prototyping. Lastly, we
            conducted usability evaluations to refine our approach.
            <div className="figure-section">
              <figure className="mockup-image-section">
                <img
                  src={processImage}
                  className="mockup-image-xl"
                  alt="Research process"
                />
              </figure>
            </div>
          </p>
          <p>
            <h3>User Interviews</h3>8 total contextual inquiries were conducted,
            and 20-40 key data points were gathered per contextual inquiry. Each
            key data point was mapped to an affinity diagram to identify themes
            and insight clusters. ‍ The study uncovered the goals and pain
            points of museum-goers. Due to the large amounts of data collected
            and diagrammed, only a portion of the mapped data is shown below. ‍
            <div className="figure-section">
              <figure className="mockup-image-section">
                <img src={interviewImage} className="mockup-image-half" />
              </figure>
            </div>
          </p>
          <h3>Personas and User Journeys</h3>
          <p>
            Through the studies, we identified key scenarios which later
            informed our usability studies. This included finding an admissions
            barcode, purchasing an item from the gift shop, and finding specific
            art pieces. ‍ The study uncovered the goals and pain points of
            gallery visitors. I mapped user attributes to a persona spectrum and
            drafted personas for gallery visitors. ‍
            <div className="figure-section">
              <figure className="mockup-image-section">
                <img src={personaSpectrum} className="mockup-image-half" />
              </figure>
              <figure className="mockup-image-section">
                <img src={personaBenjamin} className="mockup-image-xxl" />
              </figure>
              <figure className="mockup-image-section">
                <img src={personaMaude} className="mockup-image-xxl" />
              </figure>
              <figure className="mockup-image-section">
                <img src={personaJourney} className="mockup-image-xxl" />
              </figure>
            </div>
          </p>
          <h3>Turning Insights Into Ideas</h3>
          <p>
            Through the data collected, insights were uncovered. I collaborated
            with my team to reflect museum visitors' problem statements into
            feature opportunities.
            <div
              className="p3-accessibility-section-screens"
              style={{ justifyContent: "center", padding: "0px" }}
            >
              <img
                src={colorfulImage}
                className="p3-accessibility-image-screens"
                style={{ height: "100%", paddingLeft: "0px" }}
              />
              <div className="p3-accessibility-text-section">
                <h4>Motivations</h4>
                An art gallery visit can be an easy unplanned activity for
                people. If a gallery is nearby, visiting it is an activity
                people can do with minimal planning.
                <h4>Facilities</h4>
                People's expectations of facilities and actual facilities do not
                align. Most visitors do not have enough time or energy to tour
                the full gallery due to it large size, lack of air conditioning,
                or limited available seating.
                <h4>Art Viewing</h4>
                People prefer self-guided tours over staff-led tours. They use
                maps, videos, and posted information available within the museum
                to learn about the art or exhibit.{" "}
              </div>
            </div>
            <figcaption>
              Example of insights gathered from the interviews
            </figcaption>
          </p>
          <p>
            By diagramming these features on a feature-priority matrix, we were
            able to prioritize key features to include in our conceptual
            prototype and create user stories. A report-out of our insights can
            be found below to showcase research summary deliverables.
            <div className="figure-section">
              <figure className="mockup-image-section">
                <img src={featureMatrix} className="mockup-image-half" />
              </figure>
              <figure className="mockup-image-section">
                <img src={userStories} className="mockup-image-xxl" />
              </figure>
            </div>
          </p>
          <hr />
          <h2>Concept Diagram</h2>
          <p>
            Once the features to be included in the prototype were identified, I
            created a concept map to visualize interactions and relationships
            between features, users, and gallery staff.
            <div className="figure-section">
              <figure className="mockup-image-section">
                <img
                  src={conceptDiagram}
                  className="mockup-image-xl"
                  alt="Research process"
                />
              </figure>
            </div>
          </p>
          <hr />
          <h2>Wireframes and Prototyping</h2>
          <p>
            I started by creating low-fidelity wireframes to iterate the design
            and get early feedback with low effort. I then created mid-fidelity
            wireframes and a working prototype for usability testing. The
            interactive prototype enabled users to click through to fully test
            the scenarios they were given.
            <div className="figure-section">
              <figure className="mockup-image-section">
                <img
                  src={tateWireframes}
                  className="mockup-image-xxl"
                  alt="Research process"
                />
              </figure>
            </div>
          </p>
          <hr />
          <h2>Usability Evaluations</h2>
          <p>
            The team and I conducted tests of the interactive prototype to
            collect quantitative and qualitative data to assess the usability
            and user experience of the product. The evaluations were conducted
            online and in person. The evaluation tasks focused on the user's
            ability to navigate the gallery, find specific exhibitions, and
            request and purchase a postcard of a chosen art piece.
          </p>
          <p>
            ‍ I used tables to record the number and type of errors committed,
            the number of times the user asked for instruction, and the success
            or failure of each task. Participants were asked to rate the
            difficulty of each task on a scale of 1-5 and provide reasons for
            their ratings.
          </p>
          <p>
            ‍ After completing the tasks, a debriefing session was conducted to
            clarify any observed obstacles or confusing situations, gather more
            details about emotional reactions, and ask participants to rate
            their agreement with statements about their experience and
            willingness to use the product. ‍
          </p>

          <hr />
          <h2>Final Prototype and Reflections</h2>
          <div className="video-section">
            <iframe
              width="100%"
              height="800"
              src="https://embed.figma.com/proto/ahYlPfWwP38lcIF92ril7c/Tate-App?node-id=1-6&node-type=canvas&scaling=free&content-scaling=scale-down&page-id=1%3A2&starting-point-node-id=1%3A6&show-proto-sidebar=2&embed-host=share&footer=false"
              allowFullScreen
            ></iframe>
          </div>
          <p>
            <strong>What could have gone better?</strong>
            <ul className="ul-arrows">
              <li>
                One user had an issue sharing her screen, which impacted the
                evaluation.
              </li>
              <li>
                Some users were uncertain whether they were using the app as a
                user with an account or as a first-time user, leading to minor
                errors.
              </li>
              <li>
                Reviewing the protocol, which was created collaboratively,
                revealed attachments UX team members had to their ideas,
                resulting in leading questions.
              </li>
            </ul>
            <strong>What did we learn?</strong>
            <ul className="ul-arrows">
              <li>
                Data analysis showed that all users completed the Cezanne ticket
                selection task, but some users had errors or confusion regarding
                buying tickets or accessing tickets already purchased.
              </li>
              <li>
                Users generally responded positively and expressed satisfaction
                with the app, but some suggested design changes and
                clarifications for certain features, such as clickable images.
              </li>
            </ul>
          </p>
        </div>
      </div>
    </>
  );
}

export default Project5;
