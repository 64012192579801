import { useState } from "react";
import { articles } from "../data/articlesData";
import { Link } from "react-router-dom";
import "./ArticlesList.css";
import "./FilteredArticles.css";

function ArticlesList() {
  // Available filter options
  const filterOptions = ["All", "HCI Research", "UX Design", "Digital Accessibility"];

  // State to track the selected filter
  const [selectedFilter, setSelectedFilter] = useState("All");

  // Function to update the selected filter
  const handleFilterChange = (filter) => {
    setSelectedFilter(filter);
  };

  // Filter articles based on the selected filter
  const filteredArticles = articles.filter(
    (article) => selectedFilter === "All" || article.type === selectedFilter
  );

  // Sort articles and display all articles for this page
  const recentArticles = filteredArticles.sort(
    (a, b) => new Date(b.publishDate) - new Date(a.publishDate)
  );

  return (
    <section className="recent-articles-section-page">
      <div className="filter-options">
        {filterOptions.map((filter) => (
          <button
            key={filter}
            className={`filter-button ${selectedFilter === filter ? "active" : ""}`}
            onClick={() => handleFilterChange(filter)}
          >
            {filter}
          </button>
        ))}
      </div>

      {/* Display Filtered Articles */}
      <ul className="articles-list-page">
        {recentArticles.map((article) => (
          <Link
            to={`/articles/${article.slug}`}
            className="article-link full-width"
            key={article.id}
          >
            <li className="article-item-page">
              <img
                src={article.thumbnail}
                alt={`Thumbnail for ${article.title}`}
                className="article-thumbnail"
              />
              <div className="article-info-page">
              <div className="article-type-pills">
                  <span className="article-type-pill">{article.type}</span>
                </div>
                <h2>{article.title}</h2>
                <p className="publish-date">{article.publishDate}</p>

                <p className="blurb">{article.blurb}</p>
              </div>
            </li>
          </Link>
        ))}
      </ul>
    </section>
  );
}

export default ArticlesList;
