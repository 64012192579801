import React from "react";
import ProjectLayout from "../components/ProjectLayout";
import "./Project.css";
import mockup1 from "../assets/project4/peoriaplayhouse-1.png";
import mockup2 from "../assets/project4/peoriaplayhouse-2.png";
import mockup3 from "../assets/project4/peoriaplayhouse-3.gif";
import process from "../assets/project4/process.png";
import cardsort from "../assets/project4/cardsort.png";
import treetest from "../assets/project4/treetest.png";
import firstclick from "../assets/project4/firstclick.png";
import sitemap from "../assets/project4/sitemap.png";
import taskflow1 from "../assets/project4/taskflow1.png";
import taskflow2 from "../assets/project4/taskflow2.png";

function Project4() {
  return (
    <>
      <div className="dark">

        <ProjectLayout title="Redesigning the Peoria PlayHouse mobile site for improved information architecture">
          <p>
            This project focused on redesigning the information architecture
            (IA) of the Peoria PlayHouse Children's Museum’s mobile site to
            improve the overall user navigation experience. The museum’s mission
            is to provide children with tools and inspiration to explore and
            create, but its outdated website made it difficult for users to find
            critical information such as event details, tickets, and membership
            options. The primary goal of this redesign was to create a more
            intuitive structure that would serve the museum’s diverse user base,
            including caregivers, community supporters, and family gifters.
          </p>
          <p className="disclaimer-text">
            Some information in this case study may be changed to protect
            confidential or private information. All information and views
            presented in this case study are my own and do not reflect the views
            of the Peoria Playhouse Children's Museum.
          </p>
        </ProjectLayout>
      </div>
      <div className="main-content">
        <div className="case-study-details">
          <h2>The Challenge</h2>
          <p>
            The Peoria PlayHouse website faced several challenges, including
            inconsistent labeling, ambiguous categories, and a cluttered layout.
            These issues made it difficult for users to quickly locate the
            information they needed, such as museum hours, event details, and
            donation forms. Furthermore, links to external sites for key actions
            like purchasing memberships created a disjointed user experience.
            Key issues identified were:
            <ul className="ul-arrows">
              <li>
                <strong>Inconsistent navigation labels.</strong> Mixed
                task-based and topical categories confused users.
              </li>
              <li>
                <strong>Content clutter.</strong> Outdated blog posts and
                unclear categorization added unnecessary noise.
              </li>
              <li>
                <strong>Inconsistent purchasing experience.</strong>
                Some actions required navigating away from the internal site,
                creating a fragmented experience.
              </li>
            </ul>
          </p>
          <p className="emphasized-text">
            How might we make important information on site easier to find?
          </p>
          <div className="figure-section">
            <figure className="p4-mockup-image-section">
              <img
                src={mockup1}
                className="mockup-image-small"
                alt="Mockup 1"
              />
              <img
                src={mockup2}
                className="mockup-image-small"
                alt="Mockup 2"
              />
              <img
                src={mockup3}
                className="mockup-image-small"
                alt="Mockup 3"
              />
            </figure>
            <figcaption>The original Peoria Playhouse mobile site.</figcaption>
          </div>
          <hr />
          <h2>My Role</h2>
          <p>
            I completed information architecture, site and content analysis,
            usability testing, UI flow creation and UI design. I partnered with
            another UX Researcher. This project was completed over the course of
            8 weeks beginning in July 2023.
          </p>
          <hr />
          <h2>Research and Methods</h2>
          <div className="figure-section">
            <figure className="mockup-image-section">
              <img
                src={process}
                className="mockup-image-xl"
                alt="Research process"
              />
            </figure>
          </div>
          <h3>Content Inventory</h3>
          <p>
            I completed a content inventory by determining the scope of content
            being revised, documenting categorized navigation levels and pages,
            gathering and documenting URLS and page content, and identifying
            missing, inaccurate, or redundant content. ‍ The content inventory
            uncovered opportunities to remove outdated blogs and articles, lack
            of consistency in categorization themes in the navigation, ambiguous
            page labels, and links to external sites with inconsistent
            experiences.
          </p>
          <h3>User Personas & Task Analysis:</h3>
          <p>
            The team developed three key personas: Caregivers, Community
            Supporters, and Family Gifters, each representing a typical user of
            the museum’s website. Task flows were created for each persona,
            focusing on core actions like purchasing tickets, finding events,
            and making donations.
          </p>
          <p>
            Two key tasks were selected as part of the redesign because the
            purchasing experience across the site was inconsistent. Issues
            identified with the purchasing process include:
            <ul>
              <li>
                Interface design that did not match the other purchase
                experiences and the site's design
              </li>

              <li>
                Long forms with a possible 30+ fields per screen, increasing the
                likelihood of abandoned purchase attempts
              </li>

              <li>
                Required form fields for optional data with instructions to the
                user to enter "n/a"
              </li>
            </ul>
            <strong>Opportunities</strong>
            <ul>
              <li>
                Update the purchasing experience for both tasks to have a
                consistent look and feel with each other and the rest of the
                website.
              </li>
              <li>
                Group long-form fields and create a multi-page form that gives
                the user a clear understanding of where they are in the process
                and the ability to go back to make changes quickly.
              </li>
              <li>Make non-required form fields optional.</li>‍
            </ul>
            <strong>Challenges</strong> <br />
            We do not have enough information about customers' purchasing
            habits. This meant we had to make assumptions about the likelihood
            they would be a returning customer, how often they make purchases on
            the site, and whether a customer would buy multiple memberships and
            fundraiser tickets on the same day. Thus, we opted not to include
            account creation or purchasing cart due to long order forms.
            Customer interviews and data analysis can be used to improve this
            experience further.
          </p>
          <h3>Card Sorting</h3>
          <p>
            Four rounds of card sorting, including open and hybrid methods, were
            conducted to reorganize the site’s structure. The goal of our first
            open card sort among our two group members was to determine common
            groupings of 2nd level categories (25 cards) so we could propose new
            1st level categories and determine an organizational scheme for the
            mobile site.{" "}
          </p>
          <p>
            The goal of the remaining three hybrid card sorts was to test the
            newly updated 1st level categories by having users group 2nd level
            content cards under them. The end goal was a refined recommendation
            for both levels that matched users' mental models.
          </p>
          <div className="figure-section">
            <figure className="mockup-image-section">
              <img
                src={cardsort}
                className="mockup-image-xl"
                alt="Card Sort details"
              />
            </figure>
          </div>
          <p>
            These exercises helped clarify user expectations for navigation
            labels and revealed a preference for task-oriented categories like
            "Plan a Visit" and "Support the Museum."
          </p>
          <h3>Tree Jack Testing</h3>
          <p>
            The newly proposed IA was tested through tree jack testing to
            determine whether users could successfully complete key tasks, such
            as finding museum hours or purchasing a gift membership. These tests
            were performed in three rounds with a broad user base to evaluate
            success rates and fine-tune the final navigation structure.
            <div className="figure-section">
              <figure className="mockup-image-section">
                <img
                  src={treetest}
                  className="mockup-image-half"
                  alt="Tree Test details"
                />
              </figure>
            </div>
          </p>
          <h3>Wireframes & First-Click Testing</h3>
          <p>
            I conducted one round of first-click testing with 7 randomly
            selected participants. The goal was to learn about the effectiveness
            of some of the important screens in the key tasks and whether we
            could make events easier to find since we received inconsistent
            results in previous tests with the placement of the Events Calendar
            in the primary navigation menu.
          </p>
          <p>
            <strong>Analysis of the test data helped us learn...</strong>
            <br />
            Can participants initiate a membership purchase?
            <br />
            Can participants modify an error in their membership purchase?
            <br />
            Can participants initiate a fundraiser admission ticket purchase?
            <br />
            Can participants find upcoming events?
            <br />
            <div className="figure-section">
              <figure className="mockup-image-section">
                <img
                  src={firstclick}
                  className="mockup-image-xxl"
                  alt="First Click Test details"
                />
              </figure>
            </div>
          </p>
          <hr />
          <h2>Key Findings</h2>
          <p>
            The research revealed several critical insights:
            <ul className="ul-arrows">
              <li>
                <strong>Clearer Labels Were Essential. </strong>
                Inconsistent and unclear labels in the original navigation
                caused confusion for users, particularly when trying to find
                tickets or donations. Task-based labels significantly improved
                navigation.
              </li>
              <li>
                <strong>Task-Based Grouping Was Preferred. </strong>
                Users consistently grouped categories based on key actions.
                Categories like “Plan a Visit” and “Support Us” resonated with
                users as they focused on their immediate needs and goals.
              </li>
              <li>
                <strong>Improved Success in Testing </strong>
                Across the three rounds of tree jack testing, overall success
                rates for key tasks improved to 89% in the first round and
                stayed consistent at 81% in the final round. Directness, or the
                ability to find information without unnecessary detours, also
                improved significantly between rounds.
              </li>
              <li>
                <strong>First-Click Success Rates</strong>
                First-click testing showed high success rates, with 100% of
                participants correctly identifying where to initiate key actions
                like purchasing a membership or a fundraiser ticket.
              </li>
            </ul>
          </p>
          <hr />
          <h2>Solution</h2>
          <h3>Task-Oriented Information Architecture</h3>
          <p>
            Navigation was redesigned around user tasks, leading to categories
            like "Plan a Visit" and "Support Us." This structure was informed by
            the results of the card sort and tree jack tests, aligning with user
            mental models. The final site map shown was created by my project
            team mate. I provided feedback and contributed to the content.
          </p>
          <div className="figure-section">
            <figure className="mockup-image-section">
              <img
                src={sitemap}
                className="mockup-image-xxl"
                alt="Proposed site map"
              />
            </figure>
          </div>
          <h3>Improved Labeling & Consistency</h3>
          <p>
            Navigation labels were clarified and standardized to improve
            usability. Task labels, such as "Plan a Visit" and "Explore Programs
            and Exhibits," were prioritized over topical labels that caused
            confusion in earlier versions.
          </p>
          Streamlined Purchase Experience: The purchase flows for tickets,
          memberships, and donations were streamlined to avoid external site
          redirects. Wireframes introduced multi-page forms, simplifying long
          forms with clear instructions.
          <div className="figure-section">
            <figure className="mockup-image-section-vertical">
              <img
                src={taskflow1}
                className="mockup-image-xxl"
                alt="Task flow 1"
              />
              <img
                src={taskflow2}
                className="mockup-image-xxl"
                alt="Task flow 2"
              />
            </figure>
            <figcaption>Mockup of the new Peoria Playhouse user flows</figcaption>
          </div>
          <hr />
          <h2>Results & Impact</h2>
          <p>
            While this project was for academic purposes and the redesign has
            not been implemented on the live site, testing results indicate a
            significant improvement in user navigation:
            <ul className="ul-arrows">
              <li>
                89% success rate in task completion for key actions, such as
                finding museum hours or purchasing memberships, with high
                confidence in the navigation structure.
              </li>
              <li>
                100% success in first-click tests for initiating key tasks,
                demonstrating the intuitive nature of the redesign.
              </li>
              <li>
                The redesigned site structure significantly reduced the
                confusion around task completion, particularly for complex flows
                like purchasing fundraiser tickets or finding upcoming events.
              </li>
            </ul>
          </p>
          <hr />
          <h2>Future Work and Reflections</h2>
          <p>
            <ul>
              <li>
                Expand testing to a broader audience, including real-world users
                of the Peoria PlayHouse site, to gather feedback post-launch.
              </li>
              <li>
                Incorporate a customer account system to further streamline the
                purchasing process for frequent visitors.
              </li>
              <li>
                Monitor usage analytics to track how the new navigation impacts
                user engagement and task success rates over time.
              </li>
            </ul>
          </p>
        </div>
      </div>
    </>
  );
}

export default Project4;
