import { useRef } from "react";
import { motion, AnimatePresence } from "framer-motion"; 
import "./About.css";
import "../caseStudies/Project.css";
import portrait2 from "../assets/uwdqhkhe31oe8y1.jpeg";
import { LinkedinLogo, Envelope, Butterfly } from "@phosphor-icons/react";
import art1 from "../assets/about/art1.jpeg";
import pet from "../assets/about/pet.jpeg";
import blackbird from "../assets/about/blackbird.jpeg";
import pet2 from "../assets/about/pet2.jpeg";
import art2 from "../assets/about/art2.png";
import husband from "../assets/about/husband.jpeg";
import plant from "../assets/about/plant.jpeg";
import spring from "../assets/about/spring.jpeg";
import bird from "../assets/about/bird1.jpeg";
import bird2 from "../assets/about/bird2.jpeg";

const About = () => {

  const scrollRef = useRef(null);
  
  const fadeInUpVariant = {
    hidden: {
      opacity: 0,
      y: 20,
    },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.6,
        ease: "easeOut",
      },
    },
  };

  const contentVariants = {
    hidden: {
      opacity: 0,
      y: 30,
    },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 1.2, // Increased from 0.8s
        ease: "easeOut",
      },
    },
  };

  const staggerContainerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.2,
        delayChildren: 0.3,
      },
    },
  };

  const letterAnimationVariant = {
    hidden: { y: 100, opacity: 0 },
    visible: {
      y: 0,
      opacity: 1,
      transition: {
        duration: 1.5,
        ease: "easeOut",
      },
    },
  };

  const parallaxVariant = {
    hidden: { opacity: 0, y: 50 },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 1.5,
        ease: "easeOut",
      },
    },
  };

  const floatingShapesVariant = {
    animate: {
      transition: {
        staggerChildren: 0.2,
        delayChildren: 0.3,
      },
    },
  };

  const shapeVariant = {
    initial: { scale: 0, opacity: 0 },
    animate: {
      scale: 1,
      opacity: 1,
      transition: {
        duration: 1.5,
        repeat: Infinity,
        repeatType: "reverse",
        ease: "easeInOut",
      },
    },
  };

  return (
    <AnimatePresence mode="wait">
      <motion.main
        ref={scrollRef}
        className="about-wrapper"
      >
        <div className="about-dark" role="main">
          <div className="content-container-about">
            <motion.section 
              className="hero-split-section"
              aria-labelledby="main-heading"
            >
              <div className="hero-content-wrapper">
                <motion.div className="hero-text-side">
                  <motion.h1
                    id="main-heading"
                    className="hero-title"
                    initial="hidden"
                    animate="visible"
                    variants={letterAnimationVariant}
                  >
                    Johanna is a
                    <span className="highlight-text">human-centered</span>
                    researcher
                  </motion.h1>
                </motion.div>

                <div className="info-sections">
                  <motion.div className="badges-container" variants={contentVariants}>
                    <motion.div className="badge-tag" whileHover={{ scale: 1.02 }}>
                      <strong>Connect</strong>
                      <div className="contact-links">
                        <a href="mailto:contact@johannasneed.com" className="contact-link">
                          <Envelope size={15} aria-hidden="true" />
                          <span>Email</span>
                        </a>
                        <a href="https://linkedin.com/in/johannasneed" className="contact-link">
                          <LinkedinLogo size={15} />
                          <span>LinkedIn</span>
                        </a>
                        <a href="https://bsky.app/profile/johannasneed.com" className="contact-link">
                          <Butterfly size={15} aria-hidden="true" />
                          <span>BlueSky</span>
                        </a>
                      </div>
                    </motion.div>

                    <motion.div className="badge-tag" whileHover={{ scale: 1.02 }}>
                      <strong>EXPERIENCE</strong>
                      <p>Digital Experience Manager @ Pekin Insurance, leading UX and engineering of enterprise platform development.</p>
                      <p>Product Owner and Technical Architect @ State Farm, defining and supporting product strategy of internal platform development, productivity, and automation tools.</p>
                      <p>Intranet Design and Development @ State Farm and Bloomington Public Library</p>
                    </motion.div>
                    
                    <motion.div className="badge-tag" whileHover={{ scale: 1.02 }}>
                      <strong>Education</strong>
                      <p>MS in Human-Computer Interaction</p>
                      <p>BBA in Business Management</p>
                    </motion.div>
                  </motion.div>
                </div>
              </div>
            </motion.section>

            <section className="gallery-section" aria-labelledby="gallery-heading">
              <h2 id="gallery-heading" className="section-title outline">
                Favorite Things
              </h2>

              <div className="marquee-container">
                <div className="marquee-row">
                  {[
                    pet2,
                    art1,
                    bird2,
                    husband,
                    spring,
                    pet,
                    plant,
                    bird,
                    art2,
                    blackbird,
                  ].map((src, index) => (
                    <motion.div
                      key={`image-${index}`}
                      className="marquee-item"
                      whileHover={{ scale: 1.05, zIndex: 2 }}
                    >
                      <img src={src} alt={`Gallery image ${index + 1}`} />
                    </motion.div>
                  ))}
                  {[
                    pet2,
                    art1,
                    bird2,
                    husband,
                    spring,
                    pet,
                    plant,
                    bird,
                    art2,
                    blackbird,
                  ].map((src, index) => (
                    <motion.div
                      key={`image-dup-${index}`}
                      className="marquee-item"
                      whileHover={{ scale: 1.05, zIndex: 2 }}
                    >
                      <img src={src} alt={`Gallery image ${index + 1}`} />
                    </motion.div>
                  ))}
                </div>
              </div>
            </section>
          </div>
        </div>
      </motion.main>
    </AnimatePresence>
  );
};


export default About;
