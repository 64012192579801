import React from "react";
import "./404.css";

function NotFound() {
  return (
<div className="main-content-home">
<div className="nf-sticky-note-section">
<span className="nf-sticky-note yellow">What are you looking for?</span>
<span className="nf-sticky-note green">Uh oh!</span>
<span className="nf-sticky-note pink">404</span>
</div>
<h1 style={{fontSize:"2rem", textAlign:"center", paddingTop:"100px"}}>Sorry! The page you are looking for can't be found.</h1>
</div>
  )
}

export default NotFound;