import React from "react";
import {Link} from "react-router-dom";
import "./Footer.css";


const Footer = () => {
  return (
    <footer>
      <div className="footer-container">
        <ul className="footer-list">
          <li>
            <a href="mailto:contact@johannasneed.com">
              contact@johannasneed.com
            </a>
          </li>
          <li>
            <a href="https://www.linkedin.com/in/johannasneed/">LinkedIn</a>
          </li>
          <li>
            <a href="https://bsky.app/profile/johannasneed.com">Bluesky</a>
          </li>
          <li>
            <a href="https://medium.com/@johannasneed">Medium</a>
          </li>
          <li>
            <Link to="/myspace">MySpace</Link>
            
          </li>
        </ul>
        <p>Website design, content, and development &copy; 2024 Johanna Sneed. Updated 2025.</p>
      </div>
    </footer>
  );
};
export default Footer;
