import React from "react";
import ProjectLayout from "../components/ProjectLayout";
import "./Project.css";
import zocdocImage from "../assets/project2/zocdocimage.png";
import satisfactionScale from "../assets/project2/satisfactionscale.png";
import flowImage1 from "../assets/project2/flow1.png";
import flowImage2 from "../assets/project2/flow2.png";
import comboBox from "../assets/project2/combobox.png";
import sponsoredProvider from "../assets/project2/sponsoredprovider.png";
import calendarGrid from "../assets/project2/calendarGrid.png";

function Project1() {
  return (
    <>
      <div className="dark">
        <ProjectLayout title="Improving access to healthcare providers for people blindness and low vision">
          <p>
            This project evaluated the accessibility of ZocDoc.com, a healthcare
            information website, for users with blindness and low vision (BLV).
            As the population of visually impaired individuals is expected to
            double by 2050, the need for accessible digital healthcare services
            is critical. This study aimed to identify the obstacles BLV users
            encounter when navigating ZocDoc.com and provide actionable insights
            for improving the site’s accessibility, specifically focusing on
            enhancing usability through compliance with Web Content
            Accessibility Guidelines (WCAG).
          </p>
          <p className="disclaimer-text">
            Some information in this case study may be changed to protect
            confidential or private information. All information and views
            presented in this case study are my own and do not reflect the views
            of ZocDoc.
          </p>
        </ProjectLayout>
      </div>
      <div className="main-content">
        <div className="case-study-details">
          <h2>The Challenge</h2>
          <p>
            With the number of visually impaired individuals projected to double
            by 2050, ensuring healthcare websites are accessible for blind and
            low-vision (BLV) users is crucial. ZocDoc, a popular platform for
            finding healthcare providers and booking appointments, plays a
            significant role in providing healthcare access. However, many BLV
            users face barriers while navigating the site, which diminishes
            their confidence and ability to use the platform independently. Our
            goal was to evaluate the accessibility of ZocDoc.com for BLV
            individuals and uncover usability issues that hindered their
            experience.
          </p>
          <div className="figure-section">
            <figure className="mockup-image-section-vertical">
              <img
                src={zocdocImage}
                className="mockup-image-500"
                alt="ZocDoc website"
              />
            </figure>
          </div>
          <p>
            <strong>Objectives</strong>
            <ul>
              <li>
                Identify accessibility barriers for BLV users when using ZocDoc
                to search for providers and schedule appointments.
              </li>
              <li>
                Determine how the site aligns with Web Content Accessibility
                Guidelines (WCAG) and areas where it falls short. Provide
                actionable recommendations to enhance ZocDoc’s accessibility for
                BLV users.
              </li>
            </ul>
          </p>
          <hr />
          <h2>My Role</h2>
          <p>I was a UX Researcher, responsible for communications, organizing, conducting usability evaluations with participants with blind / low vision. I facilitated sessions and worked with my team to analyze the data and suggest changes.</p>
          <hr />
          <h2>Research and Methods</h2>
          <p>
            I facilitated 2 of 4 remote, moderated usability studies with blind
            participants, all of whom used screen readers to navigate the web.
            Participants were recruited using the university's network. Our
            methodology consisted of:
            <ul className="ul-arrows">
              <li>
                <strong>Task-Based Usability Testing. </strong>Participants were
                asked to complete three key tasks on ZocDoc: searching for a
                provider, selecting a provider, and scheduling an appointment.
                <br />
                <div className="figure-section">
                  <figure className="mockup-image-section-vertical">
                    <img src={flowImage1} className="mockup-image-xxl" alt="" />
                    <figcaption>
                      Happy path for searching and selecting a provider
                    </figcaption>
                  </figure>
                  <figure className="mockup-image-section-vertical">
                    <img src={flowImage2} className="mockup-image-xxl" alt="" />
                    <figcaption>
                      Happy path for viewing and scheduling an appointment with
                      a provider.
                    </figcaption>
                  </figure>
                </div>
              </li>
              <li>
                <strong>Think-Aloud Protocol. </strong> Participants verbalized
                their thoughts during tasks, allowing us to capture insights
                into their cognitive processes and challenges.
              </li>
              <li>
                <strong>Post-Task Questionnaires. </strong>Participants rated
                task difficulty, satisfaction, and confidence levels after each
                task to gauge their overall experience. Due to the low statistical significance of a small sample size, we focused on qualitative insights and patterns that objectively impacted the screen reader user experience.
                <div className="figure-section">
                  <figure className="mockup-image-section-vertical">
                    <img
                      src={satisfactionScale}
                      className="mockup-image-xl"
                      alt="ZocDoc website"
                      style={{ width: "75%" }}
                    />
                  </figure>
                </div>
              </li>
            </ul>
          </p>

          <hr />
          <h2>Key Findings</h2>
          <p>
            Our study identified significant accessibility barriers across all
            tasks. While participants were able to complete some tasks, their
            experience was marred by inconsistent navigation, improperly labeled
            elements, and incompatibility with screen readers.
            <h3>Task 1 - Search for a provider </h3>
            Although all participants completed the task, only half used the
            expected "happy path." Issues included improperly functioning combo
            boxes and navigating headings on the home page, causing confusion for participants relying on specific screen
            reader commands. Satisfaction with this task was notably low, with
            an average score of 2.47 out of 4.<br />
            <div className="figure-section">
                  <figure className="mockup-image-section-vertical">
                    <img
                      src={comboBox}
                      className="mockup-image-xl"
                      alt="ZocDoc website"
                    />
                      <figcaption>
                      While easy to navigate using a mouse, the combo box was challenging for screen reader users and shortcuts did not work as study participants expected.
                    </figcaption>
                  </figure>

                </div>
            <h3>Task 2 - Select a provider</h3>
            Participants struggled with navigating provider listings. The
            presence of sponsored listings confused some users, and two
            participants encountered issues using filters. One participant
            resorted to unconventional methods to navigate the site by navigating through each individual elements using a shortcut. The average
            satisfaction score for this task was 3.08 out of 4.
            <div className="figure-section">
                  <figure className="mockup-image-section-vertical">
                    <img
                      src={sponsoredProvider}
                      className="mockup-image-xl"
                      alt="ZocDoc website"
                    />
                      <figcaption>
                      It was unclear that the first few listings were sponsored when navigating using a screen reader. The sponsored listings are at the top of each page in the search results, making it unclear to participants that they successfully navigated to a new search results page.
                    </figcaption>
                  </figure>

                </div>
            <h3>Task 3 - Schedule an appointment</h3>
            This task proved most challenging, with only two participants
            completing it. The calendar interface was difficult to navigate via
            screen readers, and improperly labeled buttons caused confusion. Two
            participants were unable to complete the task successfully due to improper use of focus trapping, and the
            average satisfaction score for this task was 2.53 out of 4.
            <div className="figure-section">
                  <figure className="mockup-image-section-vertical">
                    <img
                      src={calendarGrid}
                      className="mockup-image-xl"
                      alt="ZocDoc website"
                    />
                      <figcaption>
At the time of the study, the calendar used the css grid display property, causing keyboard navigation to follow an unexpected top-to-bottom pattern instead of the expected left-to-right. This has since been resolved!                    </figcaption>
                  </figure>

                </div>
          </p>
          <hr />
          <h2>Design Recommendations</h2>
          <p>
          Based on our findings, we proposed several improvements to ZocDoc.com that would greatly enhance its accessibility for BLV users:

<h3>Improved Navigation and Labeling </h3>
Ensure all interactive elements (e.g., buttons, form fields, modals) are properly labeled for screen readers. Add clear headings and appropriate landmarks to improve page navigation and clarity. Streamline navigation paths and
                improve the consistency of headings and tab orders, so that BLV
                users can more easily understand and traverse the site.

<h3>Streamline Provider Listings</h3>
Group sponsored providers in a separate, clearly labeled section to avoid repetition and confusion. This allows users to skip over these listings after viewing them once.
<h3>Enhance Interactive Elements</h3>
Adjust the behavior of combo boxes and form fields to ensure they work as expected with all screen readers. Ensure that forms follow a logical tab order and do not auto-navigate to tooltips.
Redesign Appointment Scheduling: Simplify the calendar grid interface for easier screen reader navigation. Ensure modal dialogs are announced properly when opened, and provide clear instructions for selecting appointment times. Clarify and label filters to ensure
                they are accessible via screen readers and function as expected
                for all users.
          </p>
        
          <hr />
          <h2>Results & Impact</h2>
          <p>
          Impact
Our study not only identified critical usability issues but also provided actionable insights that can guide ZocDoc toward a more accessible experience for BLV users. By implementing the recommended changes, ZocDoc could:
<ul>
  <li>Enhance user satisfaction, especially for users relying on assistive technologies.    </li>
    <li>Increase task completion rates for essential actions like scheduling appointments.

  </li>
  <li>Foster greater confidence and independence among BLV users, allowing them to navigate the platform with ease.
</li>
</ul>

          </p>
          <p>
            Although the participants could complete some tasks, the overall
            usability and satisfaction scores were low, revealing substantial
            room for improvement. 
          </p>
          <hr />
          <h2>Future Work and Considerations</h2>
          <p>
            Future directions for this research include:
            <ol>
              <li>
                <strong>Accessibility audit. </strong>ZocDoc.com should undergo a formal WCAG 2.2
                audit to identify and correct the broader accessibility issues
                uncovered during this study.
              </li>
              <li>
                <strong>Cross-Site comparative study. </strong> A comparative analysis of other
                healthcare platforms could provide further insights into best
                practices for designing accessible healthcare websites for BLV
                users.
              </li>
              <li>
                <strong>Expansion of research scope.</strong>  Future research should explore
                diverse BLV user groups, including individuals with varying
                degrees of vision loss and technology experience, to further
                refine accessibility solutions.
              </li>
              <li>
                <strong>Exploration of multimodal interactions. </strong>Investigate
                opportunities to implement multimodal feedback, such as auditory
                cues or haptic feedback, to aid BLV users in navigating the
                website with more precision and confidence.
              </li>
            </ol>
          </p>
        </div>
      </div>
    </>
  );
}

export default Project1;
