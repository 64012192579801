import React from "react";
import "./Now.css";

function Now() {
  return (
    <div className="now-section">
      <h1 className="page-title" style={{color:"white"}}>Now Page</h1>
      <p>
        Learn more about Now pages at{" "}
        <a href="https://nownownow.com/about" style={{color:"white"}}>nownownow.com</a>.
      </p>
      <p>Updated: January 17, 2024</p>
      <p>
        I'm currently working on a few projects that I'm excited about:
        </p>
        <ul>
          <li>Reading Conversational Design by Erika Hall</li>
          <li>I am working on the initial research and design for UX Research product. More info coming soon!</li>
          <li>I built designed and created my portfolio from scratch in React...and site performance and SEO is a little rough, so I'm working on rebuilding in Astro. This will also bring the added bonus of better content management. </li>
          <li>
            I am wrapping up UX research and information architecture work with Y-Knot,
            Inc., a non-profit organization in Maryland offering mentorship and
            programs to underserved youth in their community.
          </li>
          <li>
            I completed the <a href="https://www.shiftingpsychotherapy.com" target="_blank" style={{color:"white"}}>Shift Psychotherapy Website</a> which should be live
            soon! I designed a new site and brand identity for a therapy
            practice in Bloomington, IL. My goal was to create a warm, welcoming
            experience that reflects the heart of their practice.
          </li>
        </ul>
    </div>
  );
}

export default Now;
