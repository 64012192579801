import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { articles } from "../data/articlesData.js";
import { seoData } from '../config/seo';
import SEO from './SEO';
import "./ArticleDetail.css";

function ArticleDetail() {
  const { slug } = useParams();
  const article = articles.find((a) => a.slug === slug);
  const seo = seoData.articles[slug];
  const [content, setContent] = useState('');

  useEffect(() => {
    if (!article) {
    }
  }, [slug, article]);

  useEffect(() => {
    if (article?.contentPath) {
      fetch(article.contentPath)
        .then(response => response.text())
        .then(html => setContent(html))
    }
  }, [article]);

  if (!article || !seo) {
    return <h2>Article Not Found</h2>;
  }

  return (
    <>
      <SEO 
        title={seo.title}
        description={seo.description}
        image={seo.image}
        type="article"
      />
      <div className="article-detail-page">
        <img
          src={article.thumbnail}
          alt={`Thumbnail for ${article.title}`}
          className="article-detail-thumbnail"
        />
        <div className="case-study-details">
          <h1>{article.title}</h1>
          <p className="publish-date">{article.publishDate}</p>
          <div className="article-content">
            <div
              dangerouslySetInnerHTML={{ __html: content }}
              style={article.customStyles || {}}
            />
          </div>
        </div>
      </div>
    </>
  );
}

export default ArticleDetail;
