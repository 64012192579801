import React from "react";
import ProjectLayout from "../components/ProjectLayout";
import { Link } from "react-router-dom";
import "./Project.css";
import competitivereview from "../assets/project1/competitive-review.png";
import accessibilityAnnotations from "../assets/project1/accessibilityannotations.png";
import projectRoadmap from "../assets/project1/projectroadmap.png";
import responsiveImage from "../assets/project1/accessibility-responsive.png";
import textImage from "../assets/project1/accessibility-text.png";
import touchImage from "../assets/project1/accessibility-touch.png";
import headingsImage from "../assets/project1/accessibility-headings.png";
import componentExample from "../assets/project1/component-example.png";
import homeWireframe from "../assets/project1/homepage-wireframe.png";
import guidelineExample from "../assets/project1/guidelines-example.png";
import { DeviceMobile, SmileyNervous, Info, Basket } from "@phosphor-icons/react";
import seventyPercent from "../assets/project1/70-percent.png";
import hundredPercent from "../assets/project1/100-percent.png";
import thirtyPercent from "../assets/project1/30-percent.png";
import guidelineIndicators from "../assets/project1/guideline-indicators.png";
import SilverDesignThumbnail from "../assets/silverdesign-image-large.png";
import silverScreen from "../assets/project1/silver-screen.png";

function Project1() {
  return (
    <>

      <div className="dark">

        <ProjectLayout
          title="Silver Design: A framework to support senior user needs"
          liveLink="https://silverux.design"
        >
          <p>
            With the lack of a design framework specifically tailored for senior
            users, UX designers and engineers often lack the tools needed to
            create accessible designs for this age group. Our team aimed to
            develop a comprehensive framework that includes design guidelines,
            an evaluation checklist, and UI components to enhance accessibility
            and inclusivity for elderly users. Silver Design is a comprehensive
            design framework created to address the unique needs of senior users
            when interacting with digital interfaces. The project identified
            significant usability challenges for this demographic and aimed to
            provide UX designers and developers with practical tools, including
            design guidelines, a checklist, and UI components that focus on
            improving accessibility for seniors.
          </p>
        </ProjectLayout>
      </div>
      <div className="main-content">
        <div className="case-study-details">
          <h2>The Challenge</h2>
          <p>
          As the elderly population grows, the demand for digital experiences tailored to users over the age of 65 becomes increasingly urgent. Senior users often face cognitive, physical, and sensory challenges, such as difficulty reading small text, navigating complex interfaces, and interacting with small or improperly labeled elements. Despite existing standards like WCAG, no design frameworks specifically address the unique needs of this demographic, leaving a critical gap in accessible design.
          </p>
          <p className="emphasized-text">
            Empowering seniors through accessible design by building interfaces
            that adapt to their needs, not the other way around.
          </p>
          
          <hr />
          <h2>My Role</h2>
          <p>
          As a UX Researcher, I conducted surveys, usability testing, and accessibility evaluations to identify the pain points of senior users. I also served as the component designer, creating initial UI elements and prototypes in Figma, and was the sole web developer for the design framework website. My responsibilities spanned research, design, and implementation, ensuring a cohesive and user-focused outcome.


          </p>
          <hr />
          <h2>Project Goals and Timeline</h2>
          <p>
            <ul className="ul-arrows">
              <li>
                Identify the needs and pain points of our demographic and
                analyze these findings/sites via web accessibility evaluations
              </li>
              <li>Choose components and design them</li>
              <li>
                Conduct user research and refine components based on feedback
              </li>
              <li>
                Develop comprehensive design guidelines and evaluation checklist
              </li>
            </ul>
          </p>
          <div className="figure-section">
            <figure className="mockup-image-section">
              <img
                src={projectRoadmap}
                className="mockup-image-xxl"
                alt="Competitive review table"
              />
            </figure>
          </div>
          <hr />
          <h2>Research and Methods</h2>
          <p>
            <h3>Competitive Review</h3>
            <p>
              We conducted a competitive review to assess the key players in the
              market. Based on the competitive review, a direct competitor of a
              UI design framework specific to elderly users does not exist. I
              was responsible for analyzing existing design frameworks and
              systems so that I could effectively build the framework site,
              while my team members focused on existing websites and
              applications. We documented our findings in a competitive review
              table, noting effictiveness of the product, component development,
              and existing guidelines.
            </p>
            <div className="figure-section">
              <figure className="mockup-image-section">
                <img
                  src={competitivereview}
                  className="mockup-image-xxl"
                  alt="Competitive review table"
                />
              </figure>
            </div>
            <h3>Secondary Research</h3>
            <p>
              We gathered academic research and verified them peer review either
              through the DePaul Library Search designation or Ulrich’s Web. We
              read through our articles to assure their applicability to our
              scope and summarized their findings as the basis for our first set
              of guidelines. </p>
              <p>
              The literature review revealed critical insights into designing for seniors. Cognitive changes, such as difficulties with memory recall and processing new information, necessitate simplified interfaces that reduce mental load while retaining essential functionality. Progressive disclosure, clear information architecture, and usability testing were emphasized as methods to achieve this balance.</p>

<p>Vision, hearing, and fine motor skills also present challenges. Research highlighted the importance of larger touch targets (at least 44px), high-contrast text, and consistent navigation patterns. Gesture-based interactions, such as drag-and-drop, were identified as difficult but learnable over time, with improvements in accuracy following practice. Design features like AssistiveTouch on iOS were noted as beneficial, but direct incorporation of such accommodations into the design is ideal.</p>

<p>The review also critiqued modern design trends that often exclude seniors. Flat design and minimalism, while aesthetically pleasing, can strip away critical affordances like clear visual cues and predictable layouts. This can lead to frustration and abandonment of tasks. By prioritizing usability over aesthetics, designers can create interfaces that serve all users, particularly seniors.</p>
            
              <p>
              
              You can <Link to="/articles/is-modern-design-ageist">read the full literature review</Link> on my blog.
            </p>
            <h3>Senior Survey</h3>
            <p>
              We surveyed nine participants between the ages of 61 and 80.
              Participants were given the survey link to complete and were
              provided with additional assistance (such as scrolling) if needed.
            </p>
            <h4>Sample of select survey quesitons for seniors</h4>
            <div className="p1-survey-questions">
                <div>
                  <strong>Habits</strong>
                  <ul className="p1-survey-questions-list">
                    <li>How often do you use a Smartphone?
                    </li>
                    <li>Do you have a preference between iOS (Apple iPhone) or Android (e.g., Samsung, Google, LG) phones?</li>
                  </ul>
                </div>
                <div>
                  <strong>Sentiment</strong>
                  <ul className="p1-survey-questions-list">
                    <li>Rate your agreement with the following statement: Technology makes my life easier.
                    </li>
                    <li>Rate your agreement with the following statement: I can usually figure out how to use new apps or devices on my own.</li>
                  </ul>
                  </div>
                <div>
                  <strong>Experiences</strong>
                  <ul className="p1-survey-questions-list">
                    <li>What are some websites or apps you enjoy using and why? </li>
                    <li>Describe a recent bad experience you had with technology. What made it difficult or frustrating? </li>
                  </ul>
                </div>
              </div>
            <p>
              The survey asked participants about their usage, attitudes, and
              preferences for web technologies. First, we asked participants
              about their use of technology, including what kind of devices they
              use, how often they use it, which operating system they prefer,
              and the purpose. Participants also rated their level of
              confidence with their technology interaction based on the Likert
              scale and shared their positive and negative experiences.
            </p>
            <h4>What we learned</h4>
              

              <div className="p1-accessibility-section">
                <div className="p1-accessibility-card">
                  <div className="p1-accessibility-img-container">
                    <DeviceMobile size={70} />
                  </div>
                  <p>
                    Most participants use a smartphone daily. While almost half of participants use tablets or laptop devices daily.
                  </p>
                </div>
                <div className="p1-accessibility-card">
                  <div className="p1-accessibility-img-container">
                  <SmileyNervous size={70} />
                  </div>
                  <p>
                    A majority of participants (88%) report that technology makes life easier, yes 1/3 of participants felt that technology is too complicated for them to figure out on their own.
                  </p>
                </div>
                <div className="p1-accessibility-card">
                  <div className="p1-accessibility-img-container">
                  <Info size={70} />
                  </div>
                  <p>
                  A majority of participants (88%) report that simple navigation and clear instructions are most important to them, several citing that they worry about falling victim to online scams on confusing sites.
                  </p>

                </div>
                <div className="p1-accessibility-card">
                  <div className="p1-accessibility-img-container">
                  <Basket size={70} />
                  </div>
                  <p>
                   Reported experiences underscore how technology enhances convenience, connectivity, learning, entertainment, and accessibility in daily life. Users reported enjoying a wide variety of sites, from online shopping to social media.
                  </p>
                </div>
              </div>
              
              <p>
                    From these results, our team narrowed down the scope of our mock site to focus on a travel website on a tablet device. 
                  </p>
                  <h3>Designer Survey</h3>
            <p>
              We created and shared a survey for our classmates, 10 designers
              from our cohort completed the survey. All participants were asked
              about their opinions on technology design for elderly users. For
              this portion, using a Likert scale, participants rated how
              strongly they agreed or disagreed with statements on seniors and
              technology.
              </p>

              <h4>Sample of select survey quesitons for designers</h4>
            <div className="p1-survey-questions">
                <div>
                  <strong>Screener</strong>
                  <ul className="p1-survey-questions-list">
                    <li>Do you have experiences witnessing seniors in your life interact with everyday technology?
                    </li>
                  </ul>
                </div>
                <div>
                  <strong>Sentiment</strong>
                  <ul className="p1-survey-questions-list">
                    <li>Rate your agreement with the following statement: It is important to adhere to accessibility standards when designing technology for seniors.</li>
                  </ul>
                  </div>
                <div>
                  <strong>Experiences</strong>
                  <ul className="p1-survey-questions-list">
                    <li>Based on your past observations, what improvements or features do you think would help make technology more accessible for seniors?</li>
                                      </ul>
                </div>
              </div>
              <p>
              
              Since not all participants have witnessed seniors using everyday
              technology, a pre-screening question was included to determine
              whether they have observed such interactions. 1 participant, who
              answered “no”, was directed to the demographic section of the
              survey. Those who answered “yes” were guided to questions about
              their observations before proceeding to the demographic questions.
            </p>


            <h4>What we learned</h4>
            <div className="p1-survey-questions">
                <div>
                  <img src={seventyPercent} className="70 percent" style={{maxWidth:"100%"}} />
                  <ul className="p1-survey-questions-list">
                    <li>70% of those surveyed strongly agreed or agreed that technology is currently senior-user friendly
                    </li>
                  </ul>
                </div>
                <div>
                  <img src={hundredPercent} className="100 percenter" style={{maxWidth:"100%"}} />
                  <ul className="p1-survey-questions-list">
                    <li>100% strongly agreed or agreed that it is important to adhere to accessibility standards when designining technology for seniors.
                    </li>
                  </ul>
                </div>
                <div>
                  <img src={thirtyPercent} className="30 percenter" style={{maxWidth:"100%"}} />
                  <ul className="p1-survey-questions-list">
                    <li>Yet, only 30% reported that they have a good understanding of user interface (UI) design principles for seniors.
                    </li>
                  </ul>
                </div>
              </div>


            <h3>Web Accessibility Evaluation</h3>
            <p>
              Based on findings from the Senior User Survey, the team chose to
              evaluate travel sites to gain insight for our design. Each member
              conducted a web accessibility evaluation on one of the following
              four travel sites, Kayak, Momondo, Southwest Airlines, and United
              Airlines. These websites were evaluated with the TAW and WCAG
              Contrast Checker methods at WCAG AAA standards. I documented and
              annotated findings in an inspiration board.
            </p>
            <div className="figure-section">
              <figure className="mockup-image-section">
                <img
                  src={accessibilityAnnotations}
                  className="mockup-image-xl"
                  alt="Competitive review table"
                />
              </figure>
            </div>
            <p>
              As a group, we identified several issues that were consistent
              among the four travel websites.
              <div className="p1-accessibility-section">
                <div className="p1-accessibility-card">
                  <div className="p1-accessibility-img-container">
                    <img src={responsiveImage} />
                  </div>
                  <p>
                    Lack of a responsive layout if a user needs to zoom in,
                    forcing users to scroll both vertically and horizontally.
                  </p>
                </div>
                <div className="p1-accessibility-card">
                  <div className="p1-accessibility-img-container">
                    <img src={textImage} />
                  </div>
                  <p>
                    Text smaller than the WCAG AAA standards minimum of 12pt
                  </p>
                </div>
                <div className="p1-accessibility-card">
                  <div className="p1-accessibility-img-container">
                    <img src={touchImage} className="accessibility-img" />
                  </div>
                  <p>
                    Interactive elements with Clickable target areas smaller
                    than 44px and spacing between selectable areas smaller than
                    24px.
                  </p>
                </div>
                <div className="p1-accessibility-card">
                  <div className="p1-accessibility-img-container">
                    <img src={headingsImage} className="accessibility-img" />
                  </div>
                  <p>
                    Interactive elements with Missing heading labels and
                    improper heading hierarchy.
                  </p>
                </div>
              </div>
              Other issues found include: unlabeled icons and low contrast text.
            </p>
            <h3>Component Creation and Prototyping</h3>
            <p>
              Based on the research findings, we created a set of UI components
              to apply to a fictitious site, Indigo Airlines. As the most
              experiences Figma user, I created the initial setup for the
              components file and prototyped the first set of interactions,
              along with guiding my team.
            </p>
            <div className="figure-section">
              <figure className="mockup-image-section-vertical">
                <img
                  src={componentExample}
                  className="mockup-image-500"
                  alt="Competitive review table"
                />
                <figcaption>
                  Example of a component I created and used in the prototype
                </figcaption>
              </figure>
            </div>
            <h3>Usability Study</h3>
            <div className="figure-section">
              <figure className="mockup-image-section-vertical">
                <img
                  src={homeWireframe}
                  className="mockup-image-500"
                  alt="Competitive review table"
                />
                <figcaption>
                  We conducted usability studies using tablet devices with
                  senior users.
                </figcaption>
              </figure>
            </div>
            <p>
              We used task-based scenarios to apply the design framework and
              allow a small group of senior users to test a prototype. Each
              participant was given 2 tasks to complete, each with a scenario
              and three follow-up questions. By measuring success through
              metrics such as task completion rates, satisfaction, and error
              rates, along with qualitative feedback, the usability study
              enabled us to evaluate the effectiveness and usability of the
              proposed design framework. We received real-time feedback for
              iterating on the first version of the Silver Design framework.
            </p>
            <div className="usability-results">
              <div className="usability-results-left">
                <h4>Find a flight</h4> <br />
                <p>
                  <span className="results-large-text">100%</span> <br />
                  Task success rate
                </p>
              </div>
              <div className="usability-results-right">
                <p>
                  <strong> Average Duration: </strong> 1min 54sec <br />
                  <strong>Average Number of Errors: </strong> 1 <br />
                  <strong>Difficulty Rating:</strong> 4.75/5
                </p>
              </div>
            </div>
            <div className="usability-results">
              <div className="usability-results-left">
                <h4>Ask a question</h4> <br />
                <p>
                  <span className="results-large-text">75%</span> <br />
                  Task success rate
                </p>
              </div>
              <div className="usability-results-right">
                <p>
                  <strong> Average Duration: </strong>2min 35sec <br />
                  <strong>Average Number of Errors: </strong> 2.25 <br />
                  <strong>Difficulty Rating:</strong> 2.5/5
                </p>
              </div>
            </div>
          </p>
          <p>
            Searching for a flight was a mostly straightforward process, but
            still had impact on cognitive load. Asking a question and navigating
            to the chat page was difficult. Many participants got lost along the
            way.
          </p>
          <p>
            <strong>Revisions</strong> <br />
            To simplify task 1, we needed to reduce visual clutter and cognitive
            load by simplifying the search process further. To make getting help
            easier, we needed to add additional search bars and create a sitemap
            for users.
          </p>
          <hr />
          <h2>Building the Design Framework</h2>
          <p>
            I used the research findings to create a design framework. For
            evaluating existing designs, we built a Design Checklist using
            established heuristics. The Design Framework includes guidelines
            for:
            <ul>
              <li>Text and Type</li>
              <li>Color and Contrast</li>
              <li>Interaction</li>
              <li>Content</li>
              <li>User Guidance</li>
            </ul>
          </p>
          
          <p>
            <h3>Human Factors Considerations</h3> <br />
            Each guideline was created to support at least one of three human
            factors impacting senior users.
            <ul className="ul-arrows">
              <li>
                <strong>Cognitive.</strong> Supports simple design that favors
                recognition rather than recall and reduction in mental load
              </li>
              <li>
                <strong>Sensory.</strong> Supports the diminishing sensory
                responses, such as hearing, sight, and touch
              </li>
              <li>
                <strong>Physical.</strong> Supports physical abilities that may change with
                age, such as fine motor skills needed to operate touch screen
                devices
              </li>
            </ul>
          </p>
          <p>As you'll see in the live project, I added indicators on the guideline pages to help designers quickly see what kind of needs they are supporting.</p>
         <img src={guidelineIndicators} alt="Guideline Indicators" className="mockup-image-half" />
         <hr />
          <h2>Solution</h2>
          <p>
            Silver Design created a set of guidelines, a design heuristic
            checklist, and UI components to address these issues.
            <ul>
              <li>Large, high-contrast text to improve readability</li>
              <li>Simple navigation structures with shallow hierarchies</li>
              <li>
                Consistent design patterns that align with seniors’ real-world
                expectations
              </li>
              <li>
                Larger touch targets to support users with physical limitations
              </li>
            </ul>
          </p>
          <a class="button-light" href="https://silverux.design" target="_blank" style={{margin:"20px 0 40px 0"}}>Visit the SilverDesign site</a>
          
          <img
            src={guidelineExample}
            alt="Guideline Example"
            className="mockup-image-xl"
          />

          <hr />
          <h2>Results and Impact</h2>


<p>Silver Design delivers practical tools to make digital interfaces more accessible for senior users:</p>

<ul>
  <li>Large, high-contrast text for readability.</li>
  
  <li>Simple navigation structures with shallow hierarchies.</li>
  
  <li>Consistent design patterns aligned with real-world expectations.</li>
  
  <li>Larger touch targets for improved usability.</li>
</ul>

Metrics from usability testing and surveys validated the framework’s effectiveness, and the heuristic checklist provided a valuable resource for designers.
<img
            src={silverScreen}
            alt="Example of the design checklist found on the Silver Design site."
            className="mockup-image-xl"
          />
          <hr />
          <h2>Future Work and Reflections</h2>
          <p>
            It is crucial to continue refining the design framework by gathering
            insights from UX designers and engineers. Their perspectives can
            provide valuable feedback on the framework’s usability and
            accessbilty to ensure it meets the needs of senior users.
          </p>
          <p>
            It is also important to explore the impact of this new design
            framework in real-world applications. Being able to apply this
            framework to more use case scenarios would help advance more
            components and help the framework evolve further.
          </p>
          <p>
            Continued development both in research and implementation would have
            an even greater impact on addressing senior user needs, some of
            which we may have no idea of since there is such a great opportunity
            in continuing to develop this precedent.
          </p>
        </div>
      </div>
    </>
  );
}

export default Project1;
