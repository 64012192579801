export const seoData = {
  home: {
    title: "Johanna Sneed - Portfolio",
    description: "Johanna Sneed's Portfolio - UX Research and Design",
    image: "/portfolio-thumbnail-new.png"
  },
  about: {
    title: "About - Johanna Sneed",
    description: "Learn about my experience in UX Research and Design",
    image: "/about-thumbnail.png"
  },
  articles: {
    'hi-fi-proto-agile': {
      title: "Balancing high fidelity prototyping and agile in digital design",
      description: "Communication and consideration of how designs are shared are critical to an effective design process.",
      image: "/static/articles/lo-fi-prototype.jpg"
    },
    'bci-and-games': {
      title: "Exploring game interaction using brain-computer interfaces",
      description: "How might low-risk, non-invasive brain-computer interface devices provide a novel and more inclusive gaming experience?",
      image: "/static/articles/gaming-bci.jpg"
    },
    'accessibility-in-sdlc': {
        title: "How to incorporate accessibility into every step of the software development lifecycle",
        description: "Accessibility should never be an afterthought. By embedding accessibility into every phase of the software development lifecycle (SDLC) and following a user-centered design process, we create products that work for everyone.",
        image: "/static/articles/accessibility-article.jpg"
    },
    'is-modern-design-ageist': {
        title: "Research backed approaches to design user interfaces for seniors",
        description: "Modern web design moves fast, and as interfaces become more minimal, one group is often left out: older adults.",
        image: "/static/articles/ageism.jpg"
    },
    'insurance-accessibility': {
      title: "Case Study: Conducting a digital accessibility audit for an insurance website",
      description: "This case study outlines an accessibility audit I conducted in May 2024, focusing on enhancing site usability for all users.",
      image: "/static/articles/piaccessibility-thumbnail.png"
    }
  }
};