import { useEffect, useRef } from "react";
import { motion, AnimatePresence } from "framer-motion"; // Remove scroll imports
import "./Home.css";
import { Link } from "react-router-dom";
import gsap from 'gsap';

const pageVariants = {
  initial: { opacity: 0 },
  animate: { opacity: 1 },
  exit: { opacity: 0 }
};

const Home = () => {

  const heroRef = useRef(null);

  // Add array of relevant words
  const words = [
    "JS"
  ];

  return (
    <AnimatePresence mode="wait">
      <motion.div
        className="page-wrapper"
        initial="initial"
        animate="animate"
        exit="exit"
        variants={pageVariants}    
      >
        <motion.section 
          className="hero-section"
        >
          <motion.div 
            className="hero-content"
          >
            <motion.div 
              className="text-container"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 1 }}
            >
              <motion.div 
                className="text-block"
                initial={{ y: 100 }}
                animate={{ y: 0 }}
                transition={{ duration: 1.2, ease: [0.6, 0.01, -0.05, 0.9] }}
              >
                <motion.div 
                  className="main-title"
                  whileHover={{ scale: 1.02 }}
                >
                  RESEARCH
                </motion.div>
                <motion.div 
                  className="plus-symbol"
                  initial={{ rotate: -90, opacity: 0 }}
                  animate={{ rotate: 0, opacity: 1 }}
                  transition={{ delay: 0.5, duration: 0.8 }}
                >
                  +
                </motion.div>
                <motion.div 
                  className="main-title outline"
                  whileHover={{ scale: 1.02 }}
                >
                  DESIGN
                </motion.div>
              </motion.div>

              <motion.div 
                className="name-tag"
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: 1 }}
              >
                by JOHANNA SNEED
              </motion.div>

              <motion.div 
                className="projects-showcase-mini"
                initial={{ opacity: 0, y: 30 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: 1.2 }}
              >
                <motion.div className="projects-list">
                  <motion.div className="project-line">
                    <div className="project-inline">
                      <span className="project-status-mini">Creator of</span>
                      <div className="project-links">
                        <Link to="https://focusboost.explorethis.quest" target="_blank" className="project-link">FocusBoost</Link>
                        <span className="project-separator">&</span>
                        <Link to="https://silverux.design" target="_blank" className="project-link">Silver Design System</Link>
                      </div>
                    </div>
                  </motion.div>
                  
                  <motion.div className="project-line">
                    <div className="project-inline">
                      <span className="project-status-mini">Working on</span>
                      <div className="project-links">
                      <Link to="https://via-labs.pages.dev" target="_blank" className="project-link">Via Labs</Link>
                      <span className="project-separator">&</span>
                        <span className="project-link">Research Companion<span className="coming-soon-badge">Coming Soon</span></span>
                      </div>
                    </div>
                  </motion.div>
                </motion.div>
              </motion.div>

            </motion.div>
          </motion.div>
        </motion.section>
      </motion.div>
    </AnimatePresence>
  );
};

export default Home;
