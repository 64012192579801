
// CaseStudy.jsx
import React from 'react';
import { useParams } from 'react-router-dom';
import Project1 from '../caseStudies/Project1';
import Project2 from '../caseStudies/Project2';
import Project4 from '../caseStudies/Project4';
import Project5 from '../caseStudies/Project5';
import Project6 from '../caseStudies/Project6';

function CaseStudy() {
  const { slug } = useParams();

  const caseStudiesMap = {
    'silver-design-framework': <Project1 />,
    'zocdoc-accessibility-research': <Project2 />,
    'childrens-museum-information-architecture': <Project4 />,
    'navigate-the-tate': <Project5 />,
    'insurance-research-strategy': <Project6 />,
  };

  return caseStudiesMap[slug] || (
    <h1 role="alert" aria-live="assertive">Case Study Not Found</h1>
  );
}

export default CaseStudy;
